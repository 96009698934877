import { DateTime } from "luxon";
import { rrulestr } from "rrule";
import { generateFrequencyString } from "./Recurrence";
import ApiHttp from "./ApiHttp";
import Filters from "./filters";
import utils from "./utils";

class BillPaySchedule {
  static STATE_PENDING = "pending";
  static STATE_PROCESSED = "processed";
  static STATE_CANCELED = "canceled";
  static STATE_RETURNED = "returned";
  static AUTHORIZATION_REQUIRED = "authorization required";

  static STATUS_SCHEDULED = [
    BillPaySchedule.STATE_PENDING,
    BillPaySchedule.AUTHORIZATION_REQUIRED,
  ];

  constructor(props) {
    this.amount = props.amount;
    this.payee_id = props.payee_id;
    this.payment_date = props.payment_date;
    this.payees = props.payees || [];
    this.ignore_warnings = props.ignore_warnings || null;
    // used during de-serialization
    this.id = props?.id;
    this.recurring_rule = props?.recurring_rule;
    this.frequency = props?.frequency;
    this.is_recurring = props?.is_recurring;
    this.state = props?.state;
    // this is feature-flagged at the moment so from_account_id may not exist
    this.from_account_id = props?.from_account_id;
    this.holidays = props?.holidays;
    this.itempotencyKey = props.itempotencyKey;
  }

  async payload() {
    const send_date = await this.sendDate();
    const payload = {
      payee_id: this.payee_id,
      expected_arrival_date: new Date(this.payment_date)
        .toISOString()
        .substring(0, 10),
      amount: utils.dollarsToPennies(this.amount),
      send_date,
    };
    // this is feature-flagged at the moment so from_account_id may not exist
    if (this.from_account_id) payload.from_account_id = this.from_account_id;
    if (this.ignore_warnings) {
      payload.ignore_warnings = this.ignore_warnings;
    }
    return payload;
  }

  sendDateFromHolidays(holidays) {
    if (!this.payment_date) return "";
    const payee = this.payees.find((p) => p.id === this.payee_id);
    if (!payee) return "";
    if (!holidays || !holidays.map) return [];
    const holidayMap = holidays.map((holiday) =>
      holiday.start_time.substring(0, 10)
    );
    let sendDate = DateTime.fromFormat(this.payment_date, "yyyy-MM-dd");
    let days = payee.delivery_options[0].transit_days;
    while (days) {
      sendDate = sendDate.minus({ days: 1 });
      if (
        ![6, 7].includes(sendDate.weekday) &&
        holidayMap.indexOf(sendDate.toFormat("yyyy-MM-dd")) === -1
      ) {
        days -= 1;
      }
    }
    return sendDate.toFormat("yyyy-MM-dd");
  }

  async sendDate() {
    const holidays = this.holidays || (await BillPaySchedule.getHolidays());
    return this.sendDateFromHolidays(holidays);
  }

  static async getHolidays() {
    const response = await ApiHttp.fetch("support/schedules/holidays", {});
    return response.holidays;
  }

  async submit() {
    const payload = await this.payload();
    const headers = this.itempotencyKey ? {"Idempotency-Key":this.itempotencyKey} : {};
    return ApiHttp.fetch("payments", { method: "POST", headers }, payload);
  }

  /**
   * Returns the description for the payment using the payee's name and account number.
   * If the payee cannot be found, "deleted payee" is used instead.
   * @returns {string}
   */
  getDescription() {
    const payee = this.payees?.find((p) => p.id === this.payee_id);
    if (!payee) return `Bill pay to deleted payee`;
    return `Bill pay to ${payee.getDisplayName()}`;
  }

  /**
   * Calculates when the next payment will arrive based on the recurrence rule.
   * Returns in "Month Day, Year" or "Next transfer: mm/dd/yyyy" format depending on
   * whether this is a recurring payment
   * @returns {string}
   */
  getNextPaymentText() {
    const rrule = rrulestr(this.recurring_rule);

    /* rrule.after is time and time-zone aware, so need to use the start of today */
    const today = DateTime.now().toUTC().startOf("day").toJSDate();
    /* need luxon.DateTime otherwise the date will be off by the number of offset hours of the time zone */
    let nextDate = DateTime.fromJSDate(rrule.after(today, true))
      .toUTC()
      .setZone("local", { keepLocalTime: true })
      .toJSDate();

    /* in the test environment, pending payments don't get processed so it's possible for one-time payments
      to have an occurrence date be before today which would cause nextDate to be an invalid date */
    if (Number.isNaN(nextDate.getTime()))
      nextDate = DateTime.fromFormat(
        this.payment_date,
        "yyyy-MM-dd"
      ).toJSDate();

    if (this.is_recurring)
      return `Next payment: ${Filters.longMonthDayYear(nextDate)}`;

    return Filters.longMonthDayYear(nextDate);
  }

  /**
   * Fetches payments and deserializes them.
   * If a next cursor is provided, recursively fetch those payments as well.
   * Optionally, if accountUuid is provided, it only returns payments
   * whose source account id matches it.
   * @param {string} [accountUuid] - uuid of the source account to filter for
   * @param {string} [queryParam] - the pagination parameter for the query
   * @returns {Payment[]}
   */
  static async fetchPayments(accountUuid, queryParam) {
    const response = await ApiHttp.fetch(`payments${queryParam || ""}`);
    if (!response?.payments) return [];

    let deserializedPayments = response.payments.map((p) =>
      this.deserialize(p)
    );

    if (response?.links?.next) {
      const nextUrl = new URL(response.links.next);
      const nextPayments = await this.fetchPayments(
        accountUuid,
        nextUrl.search
      );
      deserializedPayments.push(...nextPayments);
    }

    if (accountUuid) {
      return deserializedPayments.filter(
        (p) => p.from_account_id === accountUuid
      );
    }
    return deserializedPayments;
  }

  /**
   * Takes an array of payments and returns the subset which have not been processed
   * @param {Payment[]} payments
   * @returns {Payment[]}
   */
  static filterScheduledPayments(payments) {
    return payments.filter((payment) =>
      this.STATUS_SCHEDULED.includes(payment.state)
    );
  }

  /**
   * Converts a serialized response to a Payment.
   * @param {Object} payload
   * @param {Payee[]} [payees]
   * @returns {BillPaySchedule}
   */
  static deserialize(payload, payees) {
    let recurring_rule = payload?.recurring_rule;
    /* one-time payments might be missing DTSTART in their recurring_rule, so add it back */
    if (!recurring_rule.includes("DTSTART"))
      recurring_rule = `DTSTART:${payload?.expected_arrival_date?.replace(
        /-/g,
        ""
      )}\n${payload?.recurring_rule}`;
    const rrule = rrulestr(payload?.recurring_rule);

    return new BillPaySchedule({
      ...payload,
      amount: [null, undefined, ""].includes(payload.amount)
        ? null
        : payload.amount / 100,
      payees,
      payment_date: payload?.expected_arrival_date,
      frequency: generateFrequencyString(rrule),
      is_recurring: rrule?.options?.count !== 1,
      recurring_rule,
    });
  }
}

export default BillPaySchedule;
