var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Navigate, Routes, Route, Outlet, useNavigate, useLocation, } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import { Tabs } from "cerulean";
import { featureEquals } from "byzantine/src/Feature";
import CardManagement from "../card_settings/CardManagement";
import { UserFeaturesContextProvider, useUserFeatures, } from "../contexts/UserFeaturesContext";
import BankCardList from "./cards/BankCardList";
import UserSettingsProfilePage from "./profile_settings/UserSettingsProfilePage";
import ExternalAccountManager from "./linked_accounts/ExternalAccountManager";
import Recipients from "./recipients/Recipients";
import SecuritySettingsContainer from "./security/SecuritySettingsContainer";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import ReplaceACardContainer from "./cards/ReplaceACardContainer/ReplaceACardContainer";
import SettingsBanner from "./SettingsBanner";
import ScrollToTopOnRouteChange from "../ScrollToTopOnRouteChange";
var SettingsTabToRoute;
(function (SettingsTabToRoute) {
    SettingsTabToRoute["Profile"] = "/settings/profile";
    SettingsTabToRoute["Linked accounts"] = "/settings/linked_accounts";
    SettingsTabToRoute["Recipients"] = "/settings/recipients";
    SettingsTabToRoute["Security"] = "/settings/security";
    SettingsTabToRoute["Cards"] = "/settings/cards";
})(SettingsTabToRoute || (SettingsTabToRoute = {}));
var SettingsTabs = function () {
    var l10n = useLocalization().l10n;
    var features = useUserFeatures();
    var settings = __spreadArray(__spreadArray(__spreadArray(__spreadArray([
        "Profile"
    ], __read((features.ach ||
        features.allow_external_account_linking_without_transferable_accounts
        ? ["Linked accounts"]
        : [])), false), __read((featureEquals(features, "p2p", "m2m") ||
        features.bill_pay ||
        features.wires
        ? ["Recipients"]
        : [])), false), [
        "Security"
    ], false), __read((features.cards ? ["Cards"] : [])), false);
    var _a = __read(useState(0), 2), selectedTabIndex = _a[0], setSelectedTabIndex = _a[1];
    var location = useLocation();
    var navigate = useNavigate();
    useEffect(function () {
        if (location.pathname) {
            var index = settings.findIndex(function (setting) {
                return location.pathname.toLowerCase() === SettingsTabToRoute[setting];
            });
            if (index !== -1) {
                setSelectedTabIndex(index);
            }
        }
    }, []);
    var onTabChange = function (index) {
        setSelectedTabIndex(index);
        navigate(SettingsTabToRoute[settings[index]]);
    };
    return (_jsxs(_Fragment, { children: [_jsx(SettingsBanner, {}), _jsxs("div", { className: "margin--top--l", children: [_jsx(Tabs, { selectedIndex: selectedTabIndex, onTabChange: onTabChange, children: _jsx(Tabs.List, { children: settings.map(function (setting) {
                                var tabName = l10n.getString({
                                    Profile: "tab-settings-profile",
                                    "Linked accounts": "tab-settings-linked-accounts",
                                    Recipients: "tab-settings-recipients",
                                    Security: "tab-settings-security",
                                    Cards: "tab-settings-cards",
                                }[setting]);
                                return _jsx(Tabs.Tab, { label: tabName, tabId: setting }, setting);
                            }) }) }), _jsx(Outlet, {})] })] }));
};
var SettingsRouter = function (_a) {
    var externalAccounts = _a.externalAccounts, uatMicrodepAmntOne = _a.uatMicrodepAmntOne, uatMicrodepAmntTwo = _a.uatMicrodepAmntTwo, accounts = _a.accounts, devices = _a.devices, features = _a.features, memberships = _a.memberships;
    return (_jsx("div", { className: "settings-nav-bar-desktop", children: _jsxs(Router, { children: [_jsx(ScrollToTopOnRouteChange, {}), _jsxs(Routes, { children: [_jsx(Route, { path: "settings/cards/replace-a-card/:cardId", element: _jsx(ReplaceACardContainer, {}) }), _jsxs(Route, { path: "settings", element: _jsx(SettingsTabs, {}), children: [(features.ach ||
                                    features.allow_external_account_linking_without_transferable_accounts) && (_jsx(Route, { path: "linked_accounts", element: _jsx(ExternalAccountManager, { externalAccounts: externalAccounts, uatMicrodepAmntOne: uatMicrodepAmntOne, uatMicrodepAmntTwo: uatMicrodepAmntTwo }) })), (featureEquals(features, "p2p", "m2m") ||
                                    features.bill_pay ||
                                    features.wires) && (_jsx(Route, { path: "recipients", element: _jsx(Recipients, { accounts: accounts }) })), _jsx(Route, { path: "security", element: _jsx(SecuritySettingsContainer, { accounts: accounts, devices: devices, features: features }) }), features.cards && (_jsx(Route, { path: "cards", element: (features === null || features === void 0 ? void 0 : features.olb_enable_replace_a_card) ? (_jsx(BankCardList, {})) : (_jsx(CardManagement, {})) })), ["", "profile"].map(function (path) { return (_jsx(Route, { path: path, element: _jsx(UserSettingsProfilePage, { memberships: memberships }) }, path)); }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "" }) })] })] })] }) }));
};
var SettingsNavBarContainer = function (_a) {
    var externalAccounts = _a.externalAccounts, uatMicrodepAmntOne = _a.uatMicrodepAmntOne, uatMicrodepAmntTwo = _a.uatMicrodepAmntTwo, accounts = _a.accounts, devices = _a.devices, features = _a.features, memberships = _a.memberships;
    return (_jsx(InstitutionSettingsContextProvider, { children: _jsx(UserFeaturesContextProvider, { children: _jsx(SettingsRouter, { externalAccounts: externalAccounts, uatMicrodepAmntOne: uatMicrodepAmntOne, uatMicrodepAmntTwo: uatMicrodepAmntTwo, accounts: accounts, devices: devices, features: features, memberships: memberships }) }) }));
};
export default SettingsNavBarContainer;
