var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { DateTime } from "luxon";
// @ts-ignore - no types yet
import Utils from "./utils";
import "./types";
var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
// e.g. 1/1/2022
function americanDate(iso8601OrDate) {
    var date = handleZone(iso8601OrDate);
    return date.toFormat("M/d/yyyy");
}
function americanDatePadded(iso8601OrDate) {
    var date = handleZone(iso8601OrDate);
    return date.toFormat("MM/dd/yyyy");
}
function fractionalSecondsToAmericanDate(stamp) {
    // example: 1710357182.344221 --> 3/12/2024
    var date = new Date(stamp * 1000);
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    return "".concat(month, "/").concat(day, "/").concat(year);
}
function bullet(string) {
    if (!string) {
        return "";
    }
    return "\u2022 ".concat(string);
}
function capsToCamel(string) {
    var lowerString = string.toLowerCase();
    return lowerString.replace(/(^\w{1})|(\s+\w{1})/g, function (letter) {
        return letter.toUpperCase();
    });
}
function camelToSnake(string) {
    return string.replace(/([A-Z])/g, function (match) { return "_".concat(match.toLowerCase()); });
}
function capitalizeFirstLetter(string) {
    if (!string) {
        return "";
    }
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
function currency(input, options) {
    if (options === void 0) { options = {}; }
    var n = Number(input);
    if (Number.isNaN(n))
        return "";
    var currency = options.currency !== undefined ? options.currency : "$";
    var absoluteValue = options.absoluteValue !== undefined ? options.absoluteValue : false;
    if (!options.hasDecimal) {
        n /= 100;
    }
    if (n < 0) {
        if (!absoluteValue) {
            currency = "-".concat(currency);
        }
        n = -n;
    }
    else if (options.hasSign) {
        currency = "+".concat(currency);
    }
    var truncated = n > 0 ? Math.floor(n) : Math.ceil(n);
    var decimalized = options && options.truncate ? truncated.toString() : n.toFixed(2);
    var groupedNumber = decimalized.replace(/(\d)(?=(\d{3})+(\.|$))/g, "$1,");
    return "".concat(currency).concat(groupedNumber);
}
function dollarsToPennies(amount) {
    return Math.round(Number(amount) * 100);
}
function filterKeys(object, prefix) {
    // Returns a copy of `object` containing only those keys starting with `prefix`
    return Object.fromEntries(Object.entries(object).filter(function (_a) {
        var _b = __read(_a, 1), key = _b[0];
        return key.startsWith(prefix);
    }));
}
// If offset information is provided (e.g. iso8601 date), the date should be formatted in
// system's TZ and the "time" should not be converted. The (hh:mm) should remain the same.
// e.g. 22:00 with -05:00 offset should be displayed as 22:00 in zone with -05:00 offset.
// If no offset information is provided (e.g. pre-formatted date), the date should
// ignore any timezone conversion and display the string as it is.
function handleZone(iso8601OrDate) {
    var date = DateTime.fromISO(iso8601OrDate, { setZone: true });
    if (!date.isValid) {
        date = DateTime.fromJSDate(new Date(iso8601OrDate));
    }
    return date;
}
function humanize(string, options) {
    if (options === void 0) { options = { capitalizeFirst: true }; }
    if (!string) {
        return "";
    }
    var str = string.replace(/^[\s_]+|[\s_]+$/g, "").replace(/[_\s]+/g, " ");
    string = options.capitalizeFirst
        ? str.replace(/^[a-z]/, function (m) { return m.toUpperCase(); })
        : str;
    return Utils.formatBankingWords(string);
}
// e.g. January 1, 2022
function longMonthDayYear(iso8601OrDate) {
    var date = handleZone(iso8601OrDate);
    return date.toFormat("MMMM d, yyyy");
}
function longMonthName(monthNumber) {
    if (!(typeof monthNumber === "number")) {
        return "";
    }
    return monthNames[monthNumber];
}
function numberToText(num) {
    /* Only up to 99. numberToText(9) = "nine"
     */
    var numberToTextMap = {
        ones: [
            "",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "ten",
            "eleven",
            "twelve",
            "thirteen",
            "fourteen",
            "fifteen",
            "sixteen",
            "seventeen",
            "eighteen",
            "nineteen",
        ],
        tens: [
            "",
            "",
            "twenty",
            "thirty",
            "forty",
            "fifty",
            "sixty",
            "seventy",
            "eighty",
            "ninety",
        ],
    };
    if (num < 20)
        return numberToTextMap.ones[num];
    if (num > 99)
        return String(num);
    return "".concat(numberToTextMap.tens[Math.floor(num / 10)], "\n      ").concat(numberToText(Math.floor(num % 10)));
}
function parseCurrency(currency) {
    // parse a currency string into our internal currency format
    var matches = currency.match(/\$?([0-9.]*)/);
    if (!matches || matches.length < 2)
        return NaN;
    return parseFloat(matches[1]);
}
function penniesToDollars(amount) {
    return Number(Number(amount / 100).toFixed(2));
}
function percent(value) {
    return "".concat((Number(value) * 100).toFixed(2), "%");
}
function removeExtraSpaces(string) {
    if (!string) {
        return "";
    }
    return string.replace(/\s\s+/g, " ");
}
// e.g. Jan 1, 2022, 12:00 am
function shortMonthDayYearTime(iso8601OrDate) {
    var date = handleZone(iso8601OrDate);
    return "".concat(date.toFormat("MMM d, yyyy"), ", ").concat(time(iso8601OrDate));
}
// e.g. Jan 1, 2022
function shortMonthDayYear(iso8601OrDate) {
    var date = handleZone(iso8601OrDate);
    return "".concat(date.toFormat("MMM d, yyyy"));
}
function shortMonthName(monthNumber) {
    if (!monthNames[monthNumber]) {
        return "";
    }
    return monthNames[monthNumber].substr(0, 3);
}
function shortDate(iso8601OrDate) {
    var date = handleZone(iso8601OrDate);
    return date.toFormat("L/d/yy");
}
function snakeToCamel(string) {
    return string.replace(/(_[a-z])/g, function (match) {
        return match.toUpperCase().replace("-", "");
    });
}
// e.g. 4:20 pm
function time(iso8601OrDate) {
    var date = handleZone(iso8601OrDate);
    return date.toFormat("t").toLocaleLowerCase();
}
function titlecase(string) {
    if (!string) {
        return "";
    }
    var capitalizer = function (s) {
        return "".concat(s.charAt(0).toUpperCase()).concat(s.substr(1).toLowerCase());
    };
    string = string.replace(/\w\S*/g, capitalizer);
    return Utils.formatBankingWords(string);
}
function truncate(string, length, more) {
    if (length === void 0) { length = 60; }
    if (more === void 0) { more = "..."; }
    if (!string) {
        return "";
    }
    if (string.length > length) {
        return "".concat(string.slice(0, length)).concat(more);
    }
    return string;
}
export default {
    americanDate: americanDate,
    americanDatePadded: americanDatePadded,
    bullet: bullet,
    capsToCamel: capsToCamel,
    camelToSnake: camelToSnake,
    capitalizeFirstLetter: capitalizeFirstLetter,
    currency: currency,
    dollarsToPennies: dollarsToPennies,
    filterKeys: filterKeys,
    handleZone: handleZone,
    humanize: humanize,
    longMonthDayYear: longMonthDayYear,
    longMonthName: longMonthName,
    numberToText: numberToText,
    parseCurrency: parseCurrency,
    penniesToDollars: penniesToDollars,
    percent: percent,
    removeExtraSpaces: removeExtraSpaces,
    shortMonthDayYearTime: shortMonthDayYearTime,
    shortMonthDayYear: shortMonthDayYear,
    shortMonthName: shortMonthName,
    shortDate: shortDate,
    snakeToCamel: snakeToCamel,
    time: time,
    titlecase: titlecase,
    truncate: truncate,
    fractionalSecondsToAmericanDate: fractionalSecondsToAmericanDate,
};
