import axios from "axios";
import createHmac from "create-hmac";
import { Buffer } from "buffer";
import queryString from "query-string";
import Filters from "./filters";
import Utils from "./utils";

const DEFAULT_HEADERS = {
  Pragma: "no-cache",
  "Cache-Control": "no-store, no-cache",
  Accept: "application/json",
  "Content-Type": "application/json",
};

const DEFAULT_MAX_ATTEMPTS = 3;
const EPSILON_SECONDS = 60;

const buildAuthHeaders = (token, secret) => {
  if (!token) return {};
  const authHeaders = {
    Authorization: `Bearer ${token}`,
  };
  if (secret) {
    const created = String(Math.floor(new Date() / 1000));
    const signature = createHmac("sha256", secret)
      .update(`created: ${created}`, "utf8")
      .digest("base64");
    authHeaders.Signature = `keyId="${token}",algorithm="hmac-sha256",signature="${signature}",headers="created"`;
    authHeaders.created = created;
  }
  return authHeaders;
};

export class OtsTokenError extends Error {
  constructor(message) {
    super(message);
    this.message = message;
    this.name = "OtsTokenError";
  }
}

const getDefaultApiUrl = () => {
  try {
    var REACT_APP_PUBLIC_API_URL = process.env.REACT_APP_PUBLIC_API_URL;
    var NEXT_PUBLIC_PUBLIC_API_URL = process.env.NEXT_PUBLIC_PUBLIC_API_URL;
  } catch {
    // if process is not defined, we just want to prefix with API_VERSION
    return "";
  }
  let defaultApiUrl = "";
  if (REACT_APP_PUBLIC_API_URL) defaultApiUrl = REACT_APP_PUBLIC_API_URL;
  else if (NEXT_PUBLIC_PUBLIC_API_URL)
    defaultApiUrl = NEXT_PUBLIC_PUBLIC_API_URL;
  // we had accidentally set REACT_APP_PUBLIC_API_URL to include '/v1/' in
  // the past but then switched to letting the client manage which version
  // of the api it supports (also eat trailing '/' if present)
  return defaultApiUrl.replace(/(\/v1)?\/?$/, "");
};

// use v1 of the api
export const API_VERSION = "/v1/";

class ApiHttp {
  static isJWTExpired(token) {
    // not using atob here as that's browser only
    const payloadBase64 = token?.split(".")[1];
    if (!payloadBase64) return false;
    const decodedJson = Buffer.from(payloadBase64, "base64").toString();
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp;
    if (!exp) return false;
    const expired = new Date() >= (exp - EPSILON_SECONDS) * 1000;
    return expired;
  }

  static fetch(
    endpoint,
    options = {},
    payload = {},
    attempts = DEFAULT_MAX_ATTEMPTS
  ) {
    const method = options.method || "GET";
    const apiUrl = options.apiUrl || getDefaultApiUrl();
    const params = options.params || {};
    const endpointIsUrl = Boolean(options.endpointIsUrl);
    let getCsrf = new Promise((resolve) => resolve());

    const headers = Object.assign(
      {},
      DEFAULT_HEADERS,
      buildAuthHeaders(options.token, options.secret),
      options.headers || {}
    );

    if (ApiHttp.isJWTExpired(ApiHttp.csrfToken)) {
      ApiHttp.csrfToken = null;
    }

    if (method.toUpperCase() === "GET") {
      // if doing a get, build our querystring if not already specified
      if (!/\?/.test(endpoint)) {
        endpoint += `?${queryString.stringify(payload)}`; // eslint-disable-line no-param-reassign
      }
    } else if (ApiHttp.csrfToken) {
      getCsrf = new Promise((resolve) => {
        resolve(ApiHttp.csrfToken);
      });
    } else if (options.csrfToken) {
      getCsrf = new Promise((resolve) => {
        resolve(options.csrfToken);
      });
    } else {
      getCsrf = new Promise((resolve, reject) => {
        axios({
          url: `${apiUrl}${API_VERSION}csrf`,
          withCredentials: true,
          headers,
        })
          .then((response) => {
            ApiHttp.csrfToken = response.headers?.["x-csrftoken"];
            ApiHttp.csrfToken
              ? resolve(ApiHttp.csrfToken)
              : reject("No csrf token from server");
          })
          .catch((err) => {
            reject(err);
          });
      });
    }

    const url = endpointIsUrl ? endpoint : `${apiUrl}${API_VERSION}${endpoint}`;

    return getCsrf
      .then((csrf_token) => {
        headers["x-csrftoken"] = csrf_token;
        const data = ApiHttp.serialize(
          payload,
          options.shouldSkipSerializeAmount
        );
        return axios({
          url,
          data,
          method,
          headers,
          params,
          withCredentials: true,
          onUploadProgress: options.onUploadProgress,
        });
      })
      .then((response) => {
        if (response.data.application) {
          ApiHttp.deserialize(response.data.application);
        }
        if (response.data.applications) {
          response.data.applications.forEach((application) => {
            ApiHttp.deserialize(application);
          });
        }
        // for responses with attachments, we need the file name to save the attachment as
        if (
          (response?.headers?.["content-disposition"] || "").startsWith(
            "attachment"
          ) ||
          options.rawResponse
        ) {
          return response;
        }
        return response.data;
      })
      .catch((error) => {
        if (options.rawResponse) throw error;
        if (error.response?.status === 500) {
          throw "A server error occurred. Please try again later or contact Support.";
        }
        if (error.response?.status === 404) {
          throw "A permissions error occurred. Please contact Support.";
        }
        if (error.response && error.response.data) {
          if (
            Array.isArray(error.response.data.errors) &&
            Array.isArray(error.response.data.warnings)
          ) {
            // if we only have warnings, allow overriding
            if (
              error.response.data.warnings.length &&
              !error.response.data.errors.length
            ) {
              throw error;
            }
          }
          var message = "";
          // if we managed to get an error message from the server
          if (error.response.data.message?.non_field_errors) {
            // if we got a django serializer non-field error, use that
            message = error.response.data.message.non_field_errors;
          } else if (error.response.data.message) {
            message = error.response.data.message;
          } else {
            message = error.response.data;
          }
          // if csrf is invalid, clear it and try again
          if (/csrf/i.test(message) && error.response.status !== 500) {
            if (!attempts) throw error;
            ApiHttp.csrfToken = null;
            return ApiHttp.fetch(endpoint, options, payload, attempts - 1);
          }
          // Handle AuthorizeNet expired OTS Token, so it doesn't conflict with token below.
          if (/Invalid OTS Token/i.test(message)) {
            throw new OtsTokenError(
              "There was an error communicating to the secure payment gateway, please try again."
            );
          }
          // special handling for when your token is expired
          if (/Token/i.test(message) && /(invalid|expired)/i.test(message)) {
            if (!attempts) throw error;
            // hit login endpoint to discard any http-only token, throw expired error
            return ApiHttp.fetch("session", { apiUrl }, {}, attempts - 1);
          }
          // special handling for when a submit is attempted on an incomplete application
          if (error.response.data.id === "application_incomplete") {
            throw {
              message,
              id: error.response.data.id,
              error: new Error(),
            };
          }
          // special handling for when an mfa code is requested
          if (
            [
              "two_factor_authentication_code_requested",
              "two_factor_authentication_required",
            ].includes(error.response.data.id)
          ) {
            throw {
              message,
              id: error.response.data.id,
              seed: error.response.data.seed,
              devices: error.response.data.devices,
              device: error.response.data.device,
              error: new Error(),
            };
          }
          throw message;
        }
        throw error.message;
      });
  }

  static cullNullValues(payload) {
    // django serializers will return null values for all keys, ignore uninitialized keys
    const result = {};
    if (typeof payload === "string") return payload;
    if ([undefined, null].includes(payload)) return;
    Object.keys(payload).forEach((key) => {
      if (payload[key] !== null) {
        if (Array.isArray(payload[key])) {
          result[key] = payload[key].map((a) => ApiHttp.cullNullValues(a));
        } else if (typeof payload[key] === "object") {
          result[key] = ApiHttp.cullNullValues(payload[key]);
        } else {
          result[key] = payload[key];
        }
      }
    });
    return result;
  }

  static serialize(application, shouldSkipSerializeAmount) {
    if (application instanceof FormData) {
      return application;
    }
    const result = Utils.deepCopy(application);
    if (result.applicants && result.applicants.length) {
      result.applicants.forEach((applicant) => {
        if (applicant.date_of_birth) {
          applicant.date_of_birth = new Date(applicant.date_of_birth)
            .toISOString()
            .substr(0, 10);
        }
      });
      result.applicants[0].is_primary = true;
    }
    if (result.selected_products) {
      result.selected_products.forEach((product) => {
        if (!shouldSkipSerializeAmount) {
          product.amount = Filters.dollarsToPennies(Number(product.amount));
        }
        product.minimum_balance = Filters.dollarsToPennies(
          product.minimum_balance
        );
      });
    }
    if (result.funding) {
      result.funding.amount = Filters.dollarsToPennies(result.funding.amount);
    }
    return result;
  }

  static deserialize(application) {
    if (application.applicants) {
      application.applicants.forEach((applicant) => {
        if (applicant.date_of_birth) {
          const options = {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            timeZone: "UTC",
          };
          applicant.date_of_birth = new Date(
            applicant.date_of_birth
          ).toLocaleDateString("en-US", options);
        }
      });
    }
    if (application.selected_products) {
      application.selected_products.forEach((product) => {
        product.amount = Filters.penniesToDollars(product.amount);
        product.minimum_balance = Filters.penniesToDollars(
          product.minimum_balance
        );
      });
    }
  }

  static parseServerErrors = (errors) => {
    if (!errors) return {};
    return Object.keys(errors).reduce((acc, cv) => {
      const error = errors[cv];
      if (typeof error === "string") acc[cv] = error;
      else if (Array.isArray(error)) acc[cv] = error.join(" ");
      else if (typeof error === "object")
        acc[cv] = this.parseServerErrors(error);
      return acc;
    }, {});
  };
}

export default ApiHttp;
