import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import { LoadingShim } from "@narmi/design_system";
import ApiHttp from "byzantine/src/ApiHttp";
import Utils from "byzantine/src/utils";
import { Button, Dialog, NotificationContext, Row } from "cerulean";
import { useTranslation } from "react-i18next";

import AmountTextInput from "../form/AmountTextInput";
import utils from "../../utils";

const VerifyAccount = ({ account, uatDeposits, updateAccounts }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const [firstAmount, setFirstAmount] = useState("$");
  const [secondAmount, setSecondAmount] = useState("$");

  const { sendNotification } = useContext(NotificationContext);

  const { uatMicrodepAmntOne, uatMicrodepAmntTwo } = uatDeposits;

  const invalidErrorMessage = (amount) => {
    if (amount === "$" || amount === undefined) return "";
    const number = parseFloat(amount.replace("$", ""));
    if (Number.isNaN(number)) return t("Field must be a number");
    if (number <= 0 || number >= 1) return t("Field must be between 0 and 1");
    return "";
  };

  const onSubmit = () => {
    const payload = {
      amounts: [
        Utils.dollarsToPennies(firstAmount.replace("$", "")),
        Utils.dollarsToPennies(secondAmount.replace("$", "")),
      ],
    };
    setIsLoading(true);
    ApiHttp.fetch(
      `accounts/${account.id}/verify`,
      {
        method: "POST",
      },
      payload
    )
      .then((response) => {
        setIsOpen(false);
        setIsLoading(false);
        updateAccounts(response.accounts);
        sendNotification({
          type: "success",
          text: t("Account successfully verified!"),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.amounts[0] || utils.extractErrorMessage(err));
      });
  };

  return (
    <>
      <Button size="xs" onClick={() => setIsOpen(true)}>
        Verify
      </Button>
      <Dialog
        isOpen={isOpen}
        footer={
          <div style={{ textAlign: "right" }}>
            <Row alignItems="center" justifyContent="end">
              <Row.Item shrink>
                <Button
                  onClick={() => setIsOpen(false)}
                  kind="negative"
                  label="Cancel"
                />
              </Row.Item>
              <Row.Item shrink>
                <Button
                  onClick={onSubmit}
                  kind="primary"
                  disabled={
                    !firstAmount ||
                    !secondAmount ||
                    firstAmount === "$" ||
                    secondAmount === "$" ||
                    invalidErrorMessage(firstAmount) ||
                    invalidErrorMessage(secondAmount)
                  }
                  label={t("Confirm and activate account")}
                />
              </Row.Item>
            </Row>
          </div>
        }
        onUserDismiss={() => setIsOpen(false)}
        title={t("Verify account with deposits")}
        width="850px"
      >
        <LoadingShim isLoading={isLoading}>
          <div className="margin--top--l">
            {error && (
              <div className="message-content negative margin--bottom--m">
                <span
                  className="narmi-icon-alert-circle margin--right--s"
                  color="var(--color-errorDark)"
                />
                <span className="margin--right--l">
                  <ReactMarkdown>{error}</ReactMarkdown>
                </span>
                <Button
                  kind="plain"
                  size="m"
                  style={{ color: "var(--font-color-primary)" }}
                  label={<span className="narmi-icon-x" />}
                  onClick={() => setError("")}
                />
              </div>
            )}
            Please check your bank account ending in <b>{account.number}</b> for
            two deposits less than $1.00 that have <b>ACCTVERIFY</b> in the
            description.
            <br />
            <br />
            {t(
              "These deposits should arrive on the next business day, but may take up to 3 business days. You will have a limited number of attempts to enter these amounts."
            )}
            <br />
            <br />
            {uatMicrodepAmntOne && (
              <div className="message-content success margin--bottom--m">
                <span
                  className="narmi-icon-alert-circle margin--right--s"
                  color="var(--color-successDark)"
                />
                <span className="margin--right--l">
                  For this demo, please enter the following values:{" "}
                  {uatMicrodepAmntOne} and {uatMicrodepAmntTwo}.
                </span>
              </div>
            )}
            <div
              className="alignChild--center--top padding--y--l"
              style={{ gap: "2rem" }}
            >
              <AmountTextInput
                onChange={setFirstAmount}
                value={firstAmount}
                label={t("First Amount")}
                error={invalidErrorMessage(firstAmount)}
                style={{ width: "13rem" }}
              />
              <AmountTextInput
                onChange={setSecondAmount}
                value={secondAmount}
                label={t("Second Amount")}
                error={invalidErrorMessage(secondAmount)}
                style={{ width: "13rem" }}
              />
            </div>
          </div>
        </LoadingShim>
      </Dialog>
    </>
  );
};
VerifyAccount.propTypes = {
  account: PropTypes.object.isRequired,
  uatDeposits: PropTypes.object.isRequired,
  updateAccounts: PropTypes.func.isRequired,
};

export default VerifyAccount;
