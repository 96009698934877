import React, { useState } from "react";
import PropTypes from "prop-types";

import Account from "byzantine/src/Account";
import { ContentCard, Button } from "cerulean";
import { useTranslation } from "react-i18next";

import ExternalAccount from "./ExternalAccount";

import { UserFeaturesContextProvider } from "../contexts/UserFeaturesContext";
import { InstitutionSettingsContextProvider } from "../contexts/InstitutionSettingsContext";
import AddAccountModal from "../transfer/AddAccountModal";

const ExternalAccountManagerContainer = (props) => {
  const { t } = useTranslation();

  const [showLinkExtAccounts, setShowLinkExtAccounts] = useState(false);
  const [accounts, setAccounts] = useState(props.externalAccounts);

  // https://dev.to/samanthaming/how-to-deep-clone-an-array-in-javascript-3cig
  const clone = (items) =>
    items.map((item) => (Array.isArray(item) ? clone(item) : item));

  // From AccountSelector.jsx
  const updateAccounts = (update) => {
    const accountsUpdated = clone(accounts);
    update.forEach((acc) => {
      const account = new Account(acc);
      const idx = accountsUpdated.findIndex((a) => a.id === account.id);
      // The API POST response has a slightly different format than the GET, so manually add necessary
      // fields for the states to update correctly (nothing is being replaced, just added)
      account.account_type = acc.type;
      account.product_type = acc.product?.type;
      if (idx !== -1) {
        accountsUpdated[idx] = account;
      } else {
        accountsUpdated.push(account);
      }
    });
    setAccounts(accountsUpdated);
  };
  const editOrDeleteAccount = (uuid, newName = "", isDelete = false) => {
    const accountsUpdated = clone(accounts);
    const idx = accountsUpdated.findIndex((a) => a.id === uuid);
    if (idx < 0) return;
    if (isDelete) {
      accountsUpdated.splice(idx, 1);
    } else {
      accountsUpdated[idx] = { ...accounts[idx], nickname: newName };
    }
    setAccounts(accountsUpdated);
  };

  return (
    <InstitutionSettingsContextProvider>
      <UserFeaturesContextProvider>
        {accounts.length === 0 ? (
          <ContentCard>
            {t("No external linked accounts to display.")}
          </ContentCard>
        ) : (
          accounts.map((account, i) => (
            <ExternalAccount
              key={i}
              account={account}
              editOrDeleteAccount={editOrDeleteAccount}
              updateAccounts={updateAccounts}
              uatDeposits={{
                uatMicrodepAmntOne: props.uatMicrodepAmntOne,
                uatMicrodepAmntTwo: props.uatMicrodepAmntTwo,
              }}
            />
          ))
        )}
        {props.hasExternalAccountCreatePermission === "true" && (
          <>
            <div className="border--top padding--all--l">
              <Button
                kind="plain"
                label={t("Link an External Account")}
                onClick={() => setShowLinkExtAccounts(true)}
              />
            </div>
            <AddAccountModal
              open={showLinkExtAccounts}
              handleClose={() => setShowLinkExtAccounts(false)}
              updateAccounts={updateAccounts}
            />
          </>
        )}
      </UserFeaturesContextProvider>
    </InstitutionSettingsContextProvider>
  );
};
ExternalAccountManagerContainer.propTypes = {
  externalAccounts: PropTypes.array.isRequired,
  institution: PropTypes.object.isRequired,
  hasExternalAccountCreatePermission: PropTypes.string,
  uatMicrodepAmntOne: PropTypes.string,
  uatMicrodepAmntTwo: PropTypes.string,
};
export default ExternalAccountManagerContainer;
