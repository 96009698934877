import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { TextInput } from "@narmi/design_system";
import { isAmex } from "../CardNumberTextInput";

const getMaxLengthFromCardNumber = (cardNumber) => {
  // for our purposes, only an amex has a 4-digit cvc
  if (isAmex(cardNumber)) {
    return 4;
  }
  return 3;
};

export const validateCvc = (value) => {
  if (!value) {
    return "Required";
  }
  if (value.length < 3) {
    return "Invalid CVC";
  }
  return null;
};

const CardCvcTextInput = ({
  onChange,
  value,
  field,
  cardNumber,
  supportsFourDigitCvc,
  ...textInputProps
}) => {
  const inputRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [needCursorUpdate, setNeedCursorUpdate] = useState(false);
  // if the cardNumber changes, we should wipe the CVC code in the event that the original pin is too long
  useEffect(() => {
    const maxCvcLength = getMaxLengthFromCardNumber(cardNumber);
    let parsedValue = value.replace(/[^\d]/g, "");
    if (parsedValue.length > maxCvcLength) {
      parsedValue = "";
    }
    onChange(parsedValue);
  }, [cardNumber]);

  const handleInput = (event) => {
    const maxCvcLength = supportsFourDigitCvc
      ? getMaxLengthFromCardNumber(cardNumber)
      : 3;
    const parsedValue = event.target.value
      .substring(0, maxCvcLength)
      .replace(/[^\d]/g, "");
    onChange(parsedValue);

    const currentCursorPosition = inputRef.current.selectionStart;
    const isCursorInMiddleOfString =
      currentCursorPosition < event.target.value.length;
    if (isCursorInMiddleOfString) {
      setCursorPosition(currentCursorPosition);
      setNeedCursorUpdate(true);
    }
  };

  useEffect(() => {
    if (needCursorUpdate) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      setNeedCursorUpdate(false);
    }
  }, [needCursorUpdate]);

  return (
    <TextInput
      onChange={handleInput}
      value={value}
      field={field}
      inputMode="numeric"
      autoComplete="cc-csc"
      {...textInputProps}
      ref={inputRef}
    />
  );
};
CardCvcTextInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  field: PropTypes.string,
  cardNumber: PropTypes.string,
};

export default CardCvcTextInput;
