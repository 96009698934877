var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useLocalization } from "@fluent/react";
import Feature, { featureEquals } from "byzantine/src/Feature";
import { useUserFeatures } from "../../contexts/UserFeaturesContext";
import MemberFriendSection from "./MemberFriendSection";
import PayeeSection from "./PayeeSection";
import WireRecipientsSection from "./WireRecipientsSection";
var Recipients = function (_a) {
    var accounts = _a.accounts;
    var userFeatures = useUserFeatures();
    var displayedFeatures = __spreadArray(__spreadArray(__spreadArray([], __read((userFeatures.bill_pay ? ["Bill Pay"] : [])), false), __read((featureEquals(userFeatures, "p2p", "m2m")
        ? [useLocalization().l10n.getString("member-to-member")]
        : [])), false), __read((userFeatures.wires && (userFeatures === null || userFeatures === void 0 ? void 0 : userFeatures.beta_olb_wires_redesign)
        ? ["Wire"]
        : [])), false);
    var lastDisplayedFeature = displayedFeatures.pop();
    var commaText = displayedFeatures.join(", ");
    var andText = displayedFeatures.length >= 1 ? " and " : "";
    var featuresListText = "These are your linked recipients for ".concat(commaText).concat(andText).concat(lastDisplayedFeature, " transfers.");
    return (_jsxs("div", { children: [_jsx("div", { className: "margin--top--l", children: featuresListText }), _jsx(Feature, { features: userFeatures, flag: "bill_pay", children: _jsx(PayeeSection, {}) }), _jsx(Feature, { features: userFeatures, flag: "p2p", equals: "m2m", children: _jsx(MemberFriendSection, { accounts: accounts }) }), _jsx(Feature, { features: userFeatures, and: ["beta_olb_wires_redesign", "wires"], children: _jsx(WireRecipientsSection, {}) })] }));
};
export default Recipients;
