import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  useLoadingContext,
  useNotificationContext,
  Row,
  SeparatorList,
} from "cerulean";
import AutomaticSavings from "byzantine/src/AutomaticSavings";
import App from "byzantine/src/App";
import EditRuleDialog from "./EditRuleDialog";
import DeleteRuleDialog from "./DeleteRuleDialog";
import AppAuthorize from "../AppAuthorize";

const IneligibleRule = ({
  accountOpeningUrl,
  hasDigitalBankingAccountOpening,
  daoApp,
}) => {
  let button;
  if (accountOpeningUrl && !daoApp) {
    button = (
      <Button
        kind="primary"
        type="button"
        testId="open-account-button-url"
        label="Open an account"
        onClick={() => {
          window.location.href = accountOpeningUrl;
        }}
      />
    );
  } else if (daoApp && !hasDigitalBankingAccountOpening) {
    button = (
      <AppAuthorize app={daoApp} dataClassnames="open-an-account-button">
        <Button
          kind="primary"
          type="button"
          testId="open-account-button-dao"
          label="Open an account"
        />
      </AppAuthorize>
    );
  } else {
    button = (
      <Button
        kind="primary"
        type="button"
        testId="contact-support-button"
        label="Contact support"
        onClick={() => {
          window.location.href = "/messages";
        }}
      />
    );
  }
  return (
    <div data-testid="ineligible-state">
      <div className="fontColor--secondary">
        {`To activate Automatic Savings, you need a Checking and Savings account.`}
      </div>
      <div className="margin--top--l">{button}</div>
    </div>
  );
};
IneligibleRule.propTypes = {
  accountOpeningUrl: PropTypes.string.isRequired,
  hasDigitalBankingAccountOpening: PropTypes.bool.isRequired,
  daoApp: PropTypes.instanceOf(App),
};

const NoRule = ({ openEditDialog }) => (
  <div data-testid="inactive-state">
    <div className="fontColor--secondary margin--bottom--l">
      {`Set a rule to start saving.`}
    </div>
    <Button
      label="Choose your accounts"
      type="button"
      kind="primary"
      testId="dashboard-start-saving-button"
      data-testid="open-edit-dialog"
      onClick={openEditDialog}
    />
  </div>
);
NoRule.propTypes = {
  openEditDialog: PropTypes.func.isRequired,
};

const ActiveRule = ({ openEditDialog, openDeleteDialog }) => (
  <div data-testid="active-state">
    <div className="margin--bottom--s">
      <Row justifyContent="space-between" alignItems="center" gapSize="m">
        <Row.Item>
          <div className="fontWeight--semibold fontColor--theme--primary">
            {`3% rule`}
          </div>
        </Row.Item>
        <Row.Item shrink>
          <SeparatorList
            items={[
              <Button
                key="edit"
                kind="plain"
                type="button"
                label="Edit"
                testId="open-edit-dialog"
                onClick={openEditDialog}
              />,
              <Button
                key="delete"
                kind="plain"
                type="button"
                label="Delete"
                testId="open-delete-dialog"
                onClick={openDeleteDialog}
              />,
            ]}
          />
        </Row.Item>
      </Row>
    </div>
    <div className="fontColor--secondary">
      {`When you receive a deposit or external transfer in your checking account,
      we transfer 3% to your savings account.`}
    </div>
  </div>
);
ActiveRule.propTypes = {
  openEditDialog: PropTypes.func.isRequired,
  openDeleteDialog: PropTypes.func.isRequired,
};

const SavingsRuleCard = ({
  isActive,
  isEligible,
  accountOpeningUrl,
  accounts,
  initialSourceUuid,
  initialDestinationUuid,
  hasDigitalBankingAccountOpening,
  appsJson,
}) => {
  const { sendNotification } = useNotificationContext();
  const { setIsLoading } = useLoadingContext();
  const [sourceUuid, setSourceUuid] = useState(initialSourceUuid);
  const [destinationUuid, setDestinationUuid] = useState(
    initialDestinationUuid
  );
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  let daoApp = JSON.parse(appsJson).find((app) => app.client_id === "DAO");
  daoApp = daoApp ? App.deserialize(daoApp) : null;

  let successToastText = "";
  let savingsRule = "";

  if (!isEligible) {
    savingsRule = (
      <IneligibleRule
        accountOpeningUrl={accountOpeningUrl}
        hasDigitalBankingAccountOpening={hasDigitalBankingAccountOpening}
        daoApp={daoApp}
      />
    );
  } else if (!isActive) {
    successToastText = "Your automatic savings rule is now active.";
    savingsRule = <NoRule openEditDialog={() => setIsEditDialogOpen(true)} />;
  } else {
    successToastText = "Your automatic savings rule was successfully updated.";
    savingsRule = (
      <ActiveRule
        openEditDialog={() => setIsEditDialogOpen(true)}
        openDeleteDialog={() => setIsDeleteDialogOpen(true)}
      />
    );
  }

  return (
    <>
      <div className="savings-rules-card-wrapper">
        <h4 className="nds-sans">Savings rule</h4>
        {savingsRule}
      </div>
      <EditRuleDialog
        isEditDialogOpen={isEditDialogOpen}
        closeDialog={() => {
          setIsEditDialogOpen(false);
          setSourceUuid(initialSourceUuid);
          setDestinationUuid(initialDestinationUuid);
        }}
        accounts={accounts}
        isNewRule={!isActive}
        onSave={async () => {
          try {
            setIsEditDialogOpen(false);
            setIsLoading(true);
            await AutomaticSavings.saveRule(
              sourceUuid,
              destinationUuid,
              successToastText
            );
          } catch {
            sendNotification({
              type: "negative",
              text: "Something went wrong, please try again.",
            });
            setIsLoading(false);
          }
        }}
        sourceUuid={sourceUuid}
        onSourceChange={setSourceUuid}
        destinationUuid={destinationUuid}
        onDestinationChange={setDestinationUuid}
      />
      <DeleteRuleDialog
        isDeleteDialogOpen={isDeleteDialogOpen}
        closeDialog={() => setIsDeleteDialogOpen(false)}
        onDelete={async () => {
          try {
            setIsDeleteDialogOpen(false);
            setIsLoading(true);
            await AutomaticSavings.deleteRule(
              initialSourceUuid,
              initialDestinationUuid
            );
          } catch {
            sendNotification({
              type: "negative",
              text: "Something went wrong, please try again.",
            });
            setIsLoading(false);
          }
        }}
      />
    </>
  );
};
SavingsRuleCard.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isEligible: PropTypes.bool.isRequired,
  accountOpeningUrl: PropTypes.string.isRequired,
  accounts: PropTypes.object.isRequired,
  initialSourceUuid: PropTypes.string.isRequired,
  initialDestinationUuid: PropTypes.string.isRequired,
  hasDigitalBankingAccountOpening: PropTypes.bool.isRequired,
  appsJson: PropTypes.string,
};

export default SavingsRuleCard;
