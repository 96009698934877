import React from "react";
import PropTypes from "prop-types";
import DualApprovalRequest from "byzantine/src/DualApprovalRequest";
import ApiPaginatedList, {
  publishApiRefreshNotice,
} from "byzantine/src/ApiPaginatedList";
import DualApprovalRequestTable from "./DualApprovalRequestTable";
import Loading from "../Loading";
import PageNavigation from "../page_navigation/PageNavigation";
import Tabs from "../tabs/Tab";

import "./DualApproval.css";

function AwaitingApprovalTab({
  loading,
  selected,
  select,
  unselect,
  updateTotal,
}) {
  return (
    <ApiPaginatedList
      endpoint="approval_requests"
      keyname="approval_requests"
      pageSize={10}
    >
      {({ loading: paginationLoading, items, pagination }) => {
        if (loading || paginationLoading) {
          return <Loading />;
        }
        updateTotal(pagination.total);
        if (items.length === 0) {
          return <div className="empty-message">No approval history</div>;
        }
        const requests = items.map((a) => DualApprovalRequest.deserialize(a));
        return (
          <div>
            <DualApprovalRequestTable
              dualApprovalRequests={requests}
              selected={selected}
              select={select}
              unselect={unselect}
            />
            <PageNavigation
              pagination={pagination}
              marginClasses="margin--all--l"
            />
          </div>
        );
      }}
    </ApiPaginatedList>
  );
}
AwaitingApprovalTab.propTypes = {
  loading: PropTypes.bool.isRequired,
  selected: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired,
  unselect: PropTypes.func.isRequired,
  updateTotal: PropTypes.func.isRequired,
};

function ApprovalHistoryTab() {
  return (
    <ApiPaginatedList
      endpoint="responded_approval_requests"
      keyname="approval_requests"
      pageSize={10}
    >
      {({ loading: paginationLoading, items, pagination }) => {
        if (paginationLoading) {
          return <Loading />;
        }
        if (items.length === 0) {
          return <div className="empty-message">No approval history</div>;
        }
        const requests = items.map((a) => DualApprovalRequest.deserialize(a));
        return (
          <div>
            <DualApprovalRequestTable dualApprovalRequests={requests} />
            <div className="margin--all--l">
              <PageNavigation pagination={pagination} />
            </div>
          </div>
        );
      }}
    </ApiPaginatedList>
  );
}
ApprovalHistoryTab.propTypes = {};

export default function DualApprovalRequestList(props) {
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState({});
  const [totalAwaitingApproval, setTotalAwaitingApproval] = React.useState(
    props.approvalRequests.length
  );

  const dualApprovalRequests = props.approvalRequests.map((a) =>
    DualApprovalRequest.deserialize(a)
  );
  const select = (uuid) =>
    setSelected((prevSelected) => ({ ...prevSelected, [uuid]: true }));
  const unselect = (uuid) =>
    setSelected((prevSelected) => {
      const newSelected = { ...prevSelected };
      delete newSelected[uuid];
      return newSelected;
    });

  const numSelected = Object.keys(selected).length;
  let successfulRejectMessage;
  let successfulApproveMessage;
  if (numSelected === 1) {
    successfulRejectMessage = "Item rejected! The submitter has been notified.";
    successfulApproveMessage =
      "Item approved! The submitter has been notified.";
  } else if (numSelected > 1) {
    successfulRejectMessage =
      "Items rejected! The submitters have been notified.";
    successfulApproveMessage =
      "Items approved! The submitters have been notified.";
  } else {
    successfulRejectMessage = "No items selected.";
    successfulApproveMessage = "No items selected.";
  }
  const approvalListHeaderActions = [
    {
      label: "Reject",
      className: "ui button primary compact inverted",
      successNotification: { type: "success", text: successfulRejectMessage },
      failNotification: {
        type: "negative",
        text: "An unexpected error occurred. Please try again.",
      },
      onSubmit: (callback) => {
        setLoading(true);
        Promise.allSettled(
          dualApprovalRequests
            .filter((dar) => selected[dar.uuid])
            .map((dar) => {
              unselect(dar.uuid);
              return dar.reject();
            })
        )
          .then((resultArray) => {
            const someRejectActionsErrored = resultArray.some(
              (r) => r.status === "rejected"
            );
            if (someRejectActionsErrored) {
              // TODO figure out what error message to display.
              callback("An error occurred");
            } else {
              callback();
            }
          })
          .finally(() => {
            setLoading(false);
            publishApiRefreshNotice("approval_requests");
          });
      },
    },
    {
      label: "Approve",
      className: "ui button primary compact",
      successNotification: { type: "success", text: successfulApproveMessage },
      failNotification: {
        type: "negative",
        text: "An unexpected error occurred. Please try again.",
      },
      onSubmit: (callback) => {
        setLoading(true);
        Promise.allSettled(
          dualApprovalRequests
            .filter((dar) => selected[dar.uuid])
            .map((dar) => {
              unselect(dar.uuid);
              return dar.approve();
            })
        )
          .then((resultArray) => {
            const someApproveActionsErrored = resultArray.some(
              (r) => r.status === "rejected"
            );
            if (someApproveActionsErrored) {
              // TODO figure out what error message to display.
              callback("An error occurred");
            } else {
              callback();
            }
          })
          .finally(() => {
            setLoading(false);
            publishApiRefreshNotice("approval_requests");
          });
      },
    },
  ];
  const renderApprovalHistory = () => <ApprovalHistoryTab />;
  const renderUnapprovedList = () => (
    <AwaitingApprovalTab
      dualApprovalRequests={dualApprovalRequests}
      loading={loading}
      selected={selected}
      select={select}
      unselect={unselect}
      updateTotal={(total) => setTotalAwaitingApproval(total)} // eslint-disable-line
    />
  );
  const tabPanes = [
    {
      header: {
        text: `Pending approvals (${totalAwaitingApproval})`,
        actions: approvalListHeaderActions,
      },
      render: renderUnapprovedList,
    },
    { header: "Approval history", render: renderApprovalHistory },
  ];
  return (
    <div className="DualApprovalRequestList">
      <Tabs panes={tabPanes} />
    </div>
  );
}

DualApprovalRequestList.propTypes = {
  approvalRequests: PropTypes.array.isRequired,
  currentOrganizationUser: PropTypes.object.isRequired,
};
