import React, { useState } from "react";
import PropType from "prop-types";
import { Paperclip } from "react-feather";

import ApiHttp from "byzantine/src/ApiHttp";
import Form from "../Form";
import { openFileDialog, uploadFile } from "../../common/file_utils";

export default function ThreadReply({
  threadId,
  reloadThread,
  replyDisclaimer,
  replyTime,
  userMessageUploads,
}) {
  const [data, setData] = useState({ messageBody: "" });

  const updateData = (update) => {
    setData((prev) => Object.assign({}, prev, update));
  };

  const postMessage = async (payload) => {
    await ApiHttp.fetch(
      `threads/${threadId}/messages`,
      { method: "POST" },
      payload
    );
    updateData({ messageBody: "" });
    reloadThread();
  };

  const sendMessage = (completedCallback) => {
    postMessage({ body: data.messageBody })
      .then(completedCallback)
      .catch(() =>
        completedCallback("There was a problem sending your message.")
      );
  };

  const attachFile = async (completedCallback) => {
    try {
      const file = await openFileDialog();
      if (file) {
        const url = await uploadFile(file);
        await postMessage({ url });
      }
      completedCallback();
    } catch (err) {
      completedCallback("There was a problem attaching your file.");
    }
  };

  function getValidActions() {
    const valid_actions = [
      {
        label: "Send",
        onSubmit: sendMessage,
        successNotification: {
          type: "success",
          text: "Message sent.",
        },
        failNotification: {
          type: "negative",
          text: "There was a problem sending your message.",
        },
      },
    ];

    if (userMessageUploads) {
      valid_actions.push({
        label: "Attach",
        fancyLabel: <Paperclip />,
        className: "ui button message-icon-button",
        onSubmit: attachFile,
        noValidation: true,
      });
    }

    return valid_actions;
  }

  return (
    <div className="ui form thread-reply">
      <Form
        data={data}
        onChange={updateData}
        actions={getValidActions()}
        actionBarStyle={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "20px",
        }}
      >
        <textarea
          className="fluid field"
          placeholder="Reply to message..."
          field="messageBody"
        ></textarea>
      </Form>
      <div className="thread-reply-expected-reply-time">
        {replyTime
          ? `Typically replies within ${replyTime.toLowerCase()}.`
          : ""}
      </div>
      <div className="thread-reply-expected-reply-time">{replyDisclaimer}</div>
    </div>
  );
}
ThreadReply.defaultProps = {
  reloadThread: () => {},
};
ThreadReply.propTypes = {
  threadId: PropType.string.isRequired,
  reloadThread: PropType.func.isRequired,
  replyTime: PropType.string,
  replyDisclaimer: PropType.string,
  userMessageUploads: PropType.bool,
};
