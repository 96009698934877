import React from "react";
import { Localized, useLocalization } from "@fluent/react";
import Feature, { featureEnabled, featureEquals } from "byzantine/src/Feature";
import { modules } from "byzantine";
import OptionCard from "../OptionCard";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import ZelleLogo from "./ZelleLogo";

export default function TransferMenu() {
  // the style rules change depending on if there are an odd or even number of options =(
  const features = React.useContext(UserFeaturesContext);
  const isZelleEnabled =
    featureEquals(features, "zelle", true) ||
    featureEquals(features, "zelle", "jha") ||
    featureEquals(features, "zelle", "fis");
  const numberOfEnabledFeatures = [
    featureEnabled(features, {
      or: ["internal_transfers", "ach"],
    }),
    featureEnabled(features, { or: ["bill_pay"] }),
    featureEquals(features, "p2p", "m2m"),
    featureEnabled(features, { or: ["wires"] }),
    isZelleEnabled,
  ].filter((a) => a).length;
  const columns = numberOfEnabledFeatures < 4 ? 3 : 2;
  const { l10n } = useLocalization();

  const loanDescriptionId = features?.loan_payment_by_card
    ? "description-loan-pay-by-card"
    : "description-loan-principal-payment";

    const { showWireTransferOptionInMenu } = modules.wireTemplates.useWirePermissions();

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Localized id="heading-how-send-money">
        <h1 style={{ marginBottom: 30 }}>How do you want to send money?</h1>
      </Localized>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: `${300 * columns}px`,
        }}
      >
        <Feature features={features} or={["internal_transfers", "ach"]}>
          <OptionCard
            icon={"transfer-arrows"}
            iconSize="21px"
            title={l10n.getString("heading-transfer", null, "Transfer")}
            description={l10n.getString(
              "description-transfer",
              null,
              "Transfer funds to or from an internal or external account"
            )}
            route="/funds"
          />
        </Feature>
        <Feature features={features} flag="bill_pay">
          <OptionCard
            icon={"dollar-bill-line"}
            iconSize="28px"
            title={l10n.getString("heading-bill-pay", null, "Bill pay")}
            description={l10n.getString(
              "description-bill-pay",
              null,
              "Pay a bill online or set up future payments"
            )}
            route="/bill_pay"
          />
        </Feature>
        <Feature features={features} flag="p2p" equals="m2m">
          <OptionCard
            icon={"m2m"}
            iconSize="24px"
            title={l10n.getString("heading-m-to-m", null, "Member to member")}
            description={l10n.getString(
              "description-m-to-m",
              null,
              "Send money to a bank member using their account number"
            )}
            route="/m2m"
          />
        </Feature>
        {showWireTransferOptionInMenu ?
          <OptionCard
            icon={"wires"}
            iconSize="24px"
            title={l10n.getString("heading-wire", null, "Wire")}
            description={l10n.getString(
              "description-wire",
              null,
              "Send funds to an individual or company"
            )}
            route="/wires"
            /> 
            : 
            null
          }
        <Feature features={features} flag="zelle">
          <OptionCard
            title={<ZelleLogo />}
            description={l10n.getString(
              "description-zelle",
              null,
              "Send money to recipients at other financial institutions"
            )}
            iconSize="25px"
            route="/zelle"
          />
        </Feature>
        <Feature features={features} or={["internal_transfers", "ach"]}>
          <Feature
            features={features}
            or={["loan_principal_payment", "loan_payment_by_card"]}
          >
            <OptionCard
              icon={"loan"}
              title={l10n.getString("heading-loan")}
              description={l10n.getString(loanDescriptionId)}
              iconSize="24px"
              route="/loans"
            />
          </Feature>
        </Feature>
      </div>
    </div>
  );
}
