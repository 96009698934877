import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { memo, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { entities, modules } from "byzantine";
import DrawerLayout from "../DrawerLayout";
import BackButton from "../../BackButton";
import AddRecipientDialog from "../../../../../../../../components/transfer/wires/recipients/AddRecipientDialog";
import styles from "./RecipientForm.module.scss";
var noop = function () { return null; };
var RecipientForm = function () {
    var params = useParams();
    var recipientId = params.recipientId;
    var existingWireRecipient = entities.wires.useOneWireRecipient(recipientId);
    var navigate = useNavigate();
    var upsertOne = entities.wires.useWireRecipients().upsertOne;
    var setFieldValue = modules.wireTemplates.upsertTemplateForm.useForm().setFieldValue;
    var goBack = useCallback(function () {
        navigate(-1);
    }, [navigate]);
    var onData = useCallback(function (recipient) {
        upsertOne(recipient);
        /**
         * Temporary workaround:
         * When navigating back, useEffect overrides form fields with data from Redux.
         * This is usually correct but doesn't handle ephemeral data like a newly created
         * and selected wire recipient. To address this, wait 50 milliseconds before setting
         * the new wire recipient to ensure the form updates correctly.
         */
        setTimeout(function () {
            setFieldValue("wireRecipientId", recipient.id, true);
        }, 50);
        goBack();
        return null;
    }, [upsertOne, setFieldValue, goBack]);
    return (_jsx("div", { className: styles.formContainer, children: _jsx(DrawerLayout, { onSave: noop, onCancel: noop, isBottomBarHidden: true, children: _jsxs("div", { className: styles.container, children: [_jsx(BackButton, { onClick: goBack, style: styles.back }), _jsx("h2", { className: styles.header, children: existingWireRecipient ? "Edit recipient" : "New recipient" }), _jsx(AddRecipientDialog, { handleClose: noop, recipients: [], updateTransferFormWithRecipient: noop, setRecipients: noop, onData: onData, isNested: true, onCancel: goBack })] }) }) }));
};
export default memo(RecipientForm);
