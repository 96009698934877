import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { Dialog as NDSDialog } from "@narmi/design_system";
import { NotificationContextProvider } from "../NotificationContext";
// TODO eventually put all modal logic together?
var setGliaVisibility = function (visibility) {
    var style = "display: none".concat(visibility ? "" : " !important");
    if (document.querySelector("#salemove")) {
        document.querySelector("#salemove").setAttribute("style", style);
    }
};
var Dialog = function (_a) {
    var children = _a.children, isOpen = _a.isOpen, title = _a.title, onUserDismiss = _a.onUserDismiss, headerStyle = _a.headerStyle, footer = _a.footer, width = _a.width, testId = _a.testId;
    useEffect(function () {
        setGliaVisibility(!isOpen);
        return function () {
            setGliaVisibility(true);
        };
    }, [isOpen]);
    return (_jsx(NDSDialog, { isOpen: isOpen, title: title, onUserDismiss: onUserDismiss, headerStyle: headerStyle, footer: footer, width: width, testId: testId, children: _jsx(NotificationContextProvider, { isPortaled: false, children: children }) }));
};
export default Dialog;
