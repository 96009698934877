import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  BackCancelButton,
  Button,
  ContentCard,
  ContextForm,
  FormHeaderWithIcon,
} from "cerulean";
import AccountContext from "../../../contexts/AccountContext";
import InstitutionSettingsContext from "../../../contexts/InstitutionSettingsContext";
import RowItem from "./RowItem";

const WireTransferActionReview = ({ data, onSubmit, goBack, cancel }) => {
  const { accounts } = useContext(AccountContext);
  const { wire_display_fee } = useContext(InstitutionSettingsContext);

  const fromDisplayValue = accounts
    .find((a) => a.id === data.from_account)
    ?.getShortDescription();

  const iconComponent = (
    <div className="narmi-transfer-icon-container">
      <span className="narmi-icon-solid-circle" />
      <span className="narmi-icon-wires" />
    </div>
  );

  return (
    <>
      <FormHeaderWithIcon
        headerText="Review your wire transfer"
        iconComponent={iconComponent}
        onBack={goBack}
      />
      <div className="wire-transfer-review-card">
        <ContentCard kind="bordered">
          <RowItem rowName="From" rowValue={fromDisplayValue} />
          <RowItem rowName="To" rowValue={data.recipient?.displayValue} />
          <RowItem rowName="Amount" rowValue={data.amount} />
          {wire_display_fee && (
            <RowItem rowName="Wire fee" rowValue={wire_display_fee} />
          )}
          {data.memo && <RowItem rowName="Memo" rowValue={data.memo} isLeftAligned={true} />}
        </ContentCard>
      </div>
      <div className="alignChild--center--center form-buttons-with-spacing">
        <BackCancelButton onBack={cancel} backLabel="Cancel" />
        <ContextForm.Action onSubmit={onSubmit}>
          <div style={{ marginLeft: "auto" }}>
            <Button label="Make transfer" />
          </div>
        </ContextForm.Action>
      </div>
    </>
  );
};
WireTransferActionReview.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  cancel: PropTypes.func,
};

export default WireTransferActionReview;
