var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from "react";
import { Localized, useLocalization } from "@fluent/react";
import { featureEnabled } from "byzantine/src/Feature";
import { useLoadingContext } from "cerulean";
import App from "byzantine/src/App";
import { modules } from "byzantine";
import OptionCard from "../OptionCard";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import { useInstitutionSettings } from "../contexts/InstitutionSettingsContext";
import AppAuthorize from "../AppAuthorize";
import ZelleLogo from "./ZelleLogo";
var VALID_MOM_OPTIONS = [
    "funds",
    "bill_pay",
    "p2p",
    "wires",
    "zelle",
    "loan",
];
var transferFeatureMapping = function (flag) {
    if (flag === "funds") {
        return {
            icon: "transfer-arrows",
            iconSize: "24px",
            title: "Transfers",
            translationTitle: "heading-transfer",
            description: "Transfer funds to or from an internal or external account",
            translationDescription: "description-transfer",
            route: "/funds",
        };
    }
    if (flag === "bill_pay") {
        return {
            icon: "dollar-bill-line",
            iconSize: "24px",
            title: "Bill pay",
            translationTitle: "heading-bill-pay",
            description: "Pay a bill online or set up future payments",
            translationDescription: "description-bill-pay",
            route: "/bill_pay",
        };
    }
    if (flag === "p2p") {
        return {
            icon: "m2m",
            iconSize: "24px",
            title: "Member to member",
            translationTitle: "heading-m-to-m",
            description: "Send money to a bank member using their account number",
            translationDescription: "description-m-to-m",
            route: "/m2m",
        };
    }
    if (flag === "wires") {
        return {
            icon: "wires",
            iconSize: "24px",
            title: "Wire",
            translationTitle: "heading-wire",
            description: "Send funds to an individual or company",
            translationDescription: "description-wire",
            route: "/wires",
        };
    }
    if (flag === "zelle") {
        return {
            title: _jsx(ZelleLogo, {}),
            description: "Send money to recipients at other financial institutions",
            translationDescription: "description-zelle",
            route: "/zelle",
        };
    }
    if (flag === "loan--principal") {
        return {
            icon: "loan",
            iconSize: "24px",
            title: "Loan",
            translationTitle: "heading-loan",
            description: "Make a loan payment or one-time principal payment",
            translationDescription: "description-loan-principal-payment",
            route: "/loans",
        };
    }
    if (flag === "loan--card") {
        return {
            icon: "loan",
            iconSize: "24px",
            title: "Loan",
            translationTitle: "heading-loan",
            description: "Make a loan payment by bank account or debit card",
            translationDescription: "description-loan-pay-by-card",
            route: "/loans",
        };
    }
    return null;
};
var CustomizableTransferMenu = function () {
    var _a, _b;
    // the style rules change depending on if there are an odd or even number of options =(
    var features = useContext(UserFeaturesContext);
    var setIsLoading = useLoadingContext().setIsLoading;
    var institutionSettings = useInstitutionSettings();
    var transferMenus = (_b = (_a = institutionSettings === null || institutionSettings === void 0 ? void 0 : institutionSettings.banking_menus) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.options;
    var _c = __read(useState([]), 2), moneyMovementFeaturesData = _c[0], setMoneyMovementFeaturesData = _c[1];
    var showWireTransferOptionInMenu = modules.wireTemplates.useWirePermissions().showWireTransferOptionInMenu;
    useEffect(function () {
        // this returns an array of objects that have all information necessary to render the non-naf features
        setIsLoading(true);
        var data = transferMenus === null || transferMenus === void 0 ? void 0 : transferMenus.map(function (option) {
            var _a;
            if (option.type === "naf") {
                var app = (_a = institutionSettings === null || institutionSettings === void 0 ? void 0 : institutionSettings.apps) === null || _a === void 0 ? void 0 : _a.find(function (a) { return a.client_id === option.client_id; });
                return { type: option.type, nafData: App.deserialize(app) };
            }
            else if (option.type === "funds") {
                if (featureEnabled(features, { or: ["internal_transfers", "ach"] })) {
                    return {
                        type: option.type,
                        data: transferFeatureMapping(option.type),
                    };
                }
                return null;
            }
            else if (option.type === "loan") {
                if (featureEnabled(features, { or: ["internal_transfers", "ach"] })) {
                    if (featureEnabled(features, { or: "loan_payment_by_card" })) {
                        return {
                            type: option.type,
                            data: transferFeatureMapping("loan--card"),
                        };
                    }
                    if (featureEnabled(features, { or: "loan_principal_payment" })) {
                        return {
                            type: option.type,
                            data: transferFeatureMapping("loan--principal"),
                        };
                    }
                }
            }
            else if (option.type === "wires") {
                if (!showWireTransferOptionInMenu) {
                    return null;
                }
                return {
                    type: option.type,
                    data: transferFeatureMapping(option.type),
                };
            }
            else if (featureEnabled(features, { or: [option.type] })) {
                return {
                    type: option.type,
                    data: transferFeatureMapping(option.type),
                };
            }
            return null;
        }).filter(function (d) { return d !== null; });
        setMoneyMovementFeaturesData(data);
        if (transferMenus === null || transferMenus === void 0 ? void 0 : transferMenus.length) {
            setIsLoading(false);
        }
    }, [transferMenus, features, institutionSettings === null || institutionSettings === void 0 ? void 0 : institutionSettings.apps, showWireTransferOptionInMenu]);
    var columns = moneyMovementFeaturesData && (moneyMovementFeaturesData === null || moneyMovementFeaturesData === void 0 ? void 0 : moneyMovementFeaturesData.length) < 4 ? 3 : 2;
    var l10n = useLocalization().l10n;
    if (!moneyMovementFeaturesData) {
        return null;
    }
    return (_jsxs("div", { style: { display: "flex", flexDirection: "column", alignItems: "center" }, children: [_jsx(Localized, { id: "heading-how-send-money", children: _jsx("h1", { style: { marginBottom: 30 }, children: "How do you want to send money?" }) }), _jsx("div", { style: {
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "".concat(300 * columns, "px"),
                }, children: moneyMovementFeaturesData === null || moneyMovementFeaturesData === void 0 ? void 0 : moneyMovementFeaturesData.map(function (feature) {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
                    if ((feature === null || feature === void 0 ? void 0 : feature.type) === "naf") {
                        return (_jsx("div", { className: "margin--x--xs nds-span-12 transfer-option-card", children: _jsx(AppAuthorize, { app: feature === null || feature === void 0 ? void 0 : feature.nafData, institution: institutionSettings, children: _jsx(OptionCard, { icon: (_a = feature === null || feature === void 0 ? void 0 : feature.nafData) === null || _a === void 0 ? void 0 : _a.image, title: (_b = feature === null || feature === void 0 ? void 0 : feature.nafData) === null || _b === void 0 ? void 0 : _b.name, description: (_c = feature === null || feature === void 0 ? void 0 : feature.nafData) === null || _c === void 0 ? void 0 : _c.description, customClassName: "", iconSize: "24px" }) }) }, (_d = feature === null || feature === void 0 ? void 0 : feature.nafData) === null || _d === void 0 ? void 0 : _d.client_id));
                    }
                    if ((feature === null || feature === void 0 ? void 0 : feature.type) && VALID_MOM_OPTIONS.includes(feature === null || feature === void 0 ? void 0 : feature.type)) {
                        return (_jsx(OptionCard, { icon: (_e = feature === null || feature === void 0 ? void 0 : feature.data) === null || _e === void 0 ? void 0 : _e.icon, iconSize: (_f = feature === null || feature === void 0 ? void 0 : feature.data) === null || _f === void 0 ? void 0 : _f.iconSize, title: typeof ((_g = feature.data) === null || _g === void 0 ? void 0 : _g.title) === "string"
                                ? l10n.getString(((_h = feature === null || feature === void 0 ? void 0 : feature.data) === null || _h === void 0 ? void 0 : _h.translationTitle) || "", null, (_j = feature === null || feature === void 0 ? void 0 : feature.data) === null || _j === void 0 ? void 0 : _j.title)
                                : (_k = feature === null || feature === void 0 ? void 0 : feature.data) === null || _k === void 0 ? void 0 : _k.title, description: l10n.getString((_l = feature === null || feature === void 0 ? void 0 : feature.data) === null || _l === void 0 ? void 0 : _l.translationDescription, null, (_m = feature === null || feature === void 0 ? void 0 : feature.data) === null || _m === void 0 ? void 0 : _m.description), route: (_o = feature === null || feature === void 0 ? void 0 : feature.data) === null || _o === void 0 ? void 0 : _o.route }, feature === null || feature === void 0 ? void 0 : feature.type));
                    }
                    return null;
                }) })] }));
};
export default CustomizableTransferMenu;
