import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ContentCard, ContextForm, TextInput } from "cerulean";
import WireRecipient from "byzantine/src/WireRecipient";
import { useLocalization } from "@fluent/react";
import AccountContext from "../../../contexts/AccountContext";
import AmountTextInput from "../../../form/AmountTextInput";
import AccountSelector from "../../../AccountSelector";
import RecipientSelector from "../recipients/RecipientSelector";
import LimitsDialog from "../../LimitsDialog";

const FormContent = ({
  isBusiness = false,
  limits = {},
  recipients,
  setShowNewRecipientForm,
}) => {
  const { accounts } = useContext(AccountContext);
  const { l10n } = useLocalization();

  const limitsForDisplay = {}; // amount and available amount per time period for each transaction type
  Object.keys(limits).forEach((transaction_type) => {
    if (!["wire", "organization_user_wire"].includes(transaction_type)) return; // only want wire lmits here
    limitsForDisplay[transaction_type] = {};

    Object.keys(limits[transaction_type]).forEach((key) => {
      // limits[transaction] has numbers (which represent days) and "amount_available" as keys
      if (Number(key)) {
        // only want number keys for display
        limitsForDisplay[transaction_type][key] = limits[transaction_type][key];
      }
    });
  });

  return (
    <ContentCard kind="bordered">
      <div className="nds-sans margin--bottom--l wire-transfer-form-header">
        Wire details
      </div>
      <ContextForm.Field required>
        <AccountSelector
          field="from_account"
          label={l10n.getString("label-from", null, "From")}
          accounts={accounts.filter(
            (a) => a.isInternal() && a.isValidTransferSource()
          )}
          showAddExternalAccountLink={false}
        />
      </ContextForm.Field>
      <ContextForm.Field validate={(v) => (!v?.id ? "Required" : null)}>
        <RecipientSelector
          field="recipient"
          label={l10n.getString("label-to")}
          recipients={recipients}
          setShowNewRecipientForm={setShowNewRecipientForm}
        />
      </ContextForm.Field>
      <ContextForm.Field required>
        <AmountTextInput
          field="amount"
          label={l10n.getString("label-amount", null, "Amount")}
        />
      </ContextForm.Field>
      <ContextForm.Field
        required
        style={{ marginTop: "20px", marginBottom: 0 }}
      >
        <TextInput
          field="memo"
          label={l10n.getString("label-memo", null, "Memo")}
          maxLength={140}
        />
      </ContextForm.Field>
      <div className="margin--top--s">
        <LimitsDialog
          isWire={true}
          limitsForDisplay={limitsForDisplay}
          isBusiness={isBusiness}
        />
      </div>
    </ContentCard>
  );
};
FormContent.propTypes = {
  isBusiness: PropTypes.bool,
  limits: PropTypes.object,
  recipients: PropTypes.arrayOf(PropTypes.instanceOf(WireRecipient)),
  setShowNewRecipientForm: PropTypes.func,
};

export default FormContent;
