import React from "react";
import PropTypes from "prop-types";
import cc from "classcat";
import { DateTime } from "luxon";
import { formatNumber, Row } from "cerulean";
import imageSrc from "../../assets/images/automatic_savings.svg";

const NoActivityState = ({ isActive }) => (
  <div className="no-activity-state alignChild--center--center">
    <img alt="Automatic Savings" src={imageSrc} />
    <div className="no-activity-description">
      {isActive
        ? "Once you make or receive a deposit, we track it here."
        : "Once you set up Automatic Savings, we track your progress here."}
    </div>
  </div>
);
NoActivityState.propTypes = {
  isActive: PropTypes.bool,
};

const ActivityRow = ({ transaction, accounts }) => {
  const sourceAccount = accounts.find(
    (a) => a.id === transaction.source_account_id
  );
  const sourceAccountName = sourceAccount?.nickname || sourceAccount?.name;
  const amountSaved = formatNumber(transaction.amount / 100);

  const creationDate = DateTime.fromISO(transaction.created_at);
  const isCurrentYear = DateTime.now().year === creationDate.year;
  const creationAbbrMonth = Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format(creationDate);

  return (
    <div className="activity-row alignChild--center--center">
      <div
        className="date-cell alignChild--center--top"
        data-testid="auto-saved-date"
      >
        <div className="creation-day">{creationDate.day}</div>
        <div className="creation-month">{creationAbbrMonth}</div>
        {!isCurrentYear && (
          <div className="creation-year">{creationDate.year}</div>
        )}
      </div>
      <div className="description-cell" data-testid="auto-saved-summary">
        {`You saved ${amountSaved} from ${sourceAccountName || "a deposit"}.`}
      </div>
      <div className="amount-cell" data-testid="auto-saved-amount">
        {amountSaved}
      </div>
    </div>
  );
};
ActivityRow.propTypes = {
  transaction: PropTypes.object,
  accounts: PropTypes.array,
};

const ViewAccountButton = ({ recentSavingsId }) => {
  const navigateToAccount = () => {
    window.location.href = `/accounts/${recentSavingsId}`;
  };
  return (
    <div
      role={recentSavingsId ? "button" : undefined}
      tabIndex={recentSavingsId ? "0" : undefined}
      className={cc(["view-account-button", { clickable: recentSavingsId }])}
      onKeyUp={
        recentSavingsId
          ? ({ key }) => {
              if (key === "Enter") {
                navigateToAccount();
              }
            }
          : undefined
      }
      onClick={recentSavingsId ? navigateToAccount : undefined}
    >
      <Row>
        <Row.Item>View account</Row.Item>
        <Row.Item shrink>
          <span className="narmi-icon-chevron-right" />
        </Row.Item>
      </Row>
    </div>
  );
};
ViewAccountButton.propTypes = {
  recentSavingsId: PropTypes.string,
};

const RecentActivityCard = ({
  isActive,
  accounts = [],
  recentTransactions = [],
}) => {
  const hasTransactions = recentTransactions && recentTransactions?.length > 0;
  return (
    <div className="recent-activity-card-wrapper">
      <div className="margin--left--l margin--right--l">
        <h2>{`Recent activity`}</h2>
        {hasTransactions ? (
          <div className="margin--bottom--xl">
            {recentTransactions?.map((t) => (
              <ActivityRow key={t.id} transaction={t} accounts={accounts} />
            ))}
          </div>
        ) : (
          <NoActivityState isActive={isActive} />
        )}
      </div>
      <ViewAccountButton
        recentSavingsId={
          hasTransactions ? recentTransactions[0].destination_account_id : null
        }
      />
    </div>
  );
};
RecentActivityCard.propTypes = {
  isActive: PropTypes.bool.isRequired,
  accounts: PropTypes.array.isRequired,
  recentTransactions: PropTypes.array.isRequired,
};

export default RecentActivityCard;
