var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useLocalization } from "@fluent/react";
import { Button, Checkbox, Dialog, ContextForm, Row, useFormData, useNotificationContext, } from "cerulean";
import ApiHttp from "byzantine/src/ApiHttp";
var UpdatedTOSBody = function (_a) {
    var termsUrl = _a.termsUrl, setOnUserDismiss = _a.setOnUserDismiss;
    var sendNotification = useNotificationContext().sendNotification;
    var l10n = useLocalization().l10n;
    var _b = useFormData({}), formData = _b.formData, onChange = _b.onChange;
    var _c = __read(useState("/?success=".concat(l10n
        .getString("tos-acceptance-success-toast")
        .split(" ")
        .join("+"))), 2), redirectUrl = _c[0], setRedirectUrl = _c[1];
    useEffect(function () {
        /* grabbing the URI that the user was navigating to before the redirect */
        var queryParams = new URLSearchParams(window.location.search);
        if (!queryParams.get("next")) {
            return;
        }
        var newUrl = new URL(queryParams.get("next"), window.location.origin);
        var newParams = new URLSearchParams(newUrl.search);
        newParams.append("success", l10n.getString("tos-acceptance-success-toast"));
        setRedirectUrl("".concat(newUrl.pathname, "?").concat(newParams.toString()));
        queryParams.delete("next");
        window.history.replaceState({}, "", "".concat(document.location.href.split("?")[0], "?").concat(queryParams.toString()));
    }, []);
    useEffect(function () {
        if (sendNotification) {
            setOnUserDismiss(function () { return function () {
                sendNotification({
                    type: "negative",
                    text: l10n.getString("dismiss-tos-dialog-error"),
                });
            }; });
        }
    }, [sendNotification]);
    var onSubmit = function (callback) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, ApiHttp.fetch("terms", { method: "POST" })];
                case 1:
                    _a.sent();
                    window.history.replaceState({}, "", redirectUrl);
                    window.location.reload();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    callback();
                    sendNotification({
                        type: "negative",
                        text: err_1,
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "margin--bottom--xl margin--top--xs", children: l10n.getString("tos-dialog-instructions") }), _jsx("div", { className: "margin--bottom--xl", children: _jsx(Button, { kind: "plain", label: l10n.getString("tos-link-button-label"), startIcon: "file-text1", as: "a", href: termsUrl, target: "_blank", rel: "noopener noreferrer" }) }), _jsxs(ContextForm, { data: formData, onChange: onChange, children: [_jsx(ContextForm.Field, { required: true, className: "margin--bottom--xl", children: _jsx(Checkbox, { field: "accept-tos", label: l10n.getString("tos-acceptance-checkbox-label") }) }), _jsx(Row, { alignItems: "center", justifyContent: "end", children: _jsx(Row.Item, { shrink: true, children: _jsx(ContextForm.Action, { onSubmit: onSubmit, children: _jsx(Button, { kind: "primary", label: l10n.getString("tos-submit-button-label") }) }) }) })] })] }));
};
var UpdatedTOSDialog = function (_a) {
    var termsUrl = _a.termsUrl;
    var l10n = useLocalization().l10n;
    var _b = __read(useState(function () { }), 2), onUserDismiss = _b[0], setOnUserDismiss = _b[1];
    return (_jsx(Dialog, { onUserDismiss: onUserDismiss, title: l10n.getString("tos-dialog-title"), isOpen: true, children: _jsx(UpdatedTOSBody, { termsUrl: termsUrl, setOnUserDismiss: setOnUserDismiss }) }));
};
export default UpdatedTOSDialog;
