import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import ApiHttp from "byzantine/src/ApiHttp";
import { Button, Dialog, LoadingContext, NotificationContext } from "cerulean";
import { Popover } from "@narmi/design_system";
import { useTranslation } from "react-i18next";

const Option = ({ text, onClick }) => (
  <div
    className="brand-hover padding--y--s padding--x--xs option"
    role="button"
    onKeyUp={({ key }) => {
      if (key === "Enter") onClick();
    }}
    tabIndex={0}
    onClick={onClick}
  >
    <span />
    <div className="margin--left--xs">{text}</div>
  </div>
);
Option.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

const Options = ({ account, deleteAccount, setIsEditingName }) => {
  const { t } = useTranslation();

  const { sendNotification } = useContext(NotificationContext);
  const { setIsLoading } = useContext(LoadingContext);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleDelete = () => {
    setIsLoading(true);

    ApiHttp.fetch(`accounts/${account.id}`, {
      method: "DELETE",
    })
      .then(() => {
        setIsLoading(false);
        setIsConfirmationModalOpen(false);
        sendNotification({
          type: "success",
          text: t("Account successfully unlinked"),
        });
        deleteAccount(account.id, "", true);
      })
      .catch((err) => {
        setIsLoading(false);
        sendNotification({
          type: "negative",
          text: t(err),
        });
      });
  };

  return (
    <>
      <Popover
        closeOnContentClick
        content={
          <div className="popover-content">
            <Option
              text={account.verified ? t("Unlink") : t("Cancel")}
              onClick={() => setIsConfirmationModalOpen(true)}
            />
            <Option
              text={t("Edit name")}
              onClick={() => setIsEditingName(true)}
            />
          </div>
        }
      >
        <span className="narmi-icon-more-vertical clickable"></span>
      </Popover>
      <Dialog
        isOpen={isConfirmationModalOpen}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              kind="negative"
              onClick={() => setIsConfirmationModalOpen(false)}
            >
              {t("Cancel")}
            </Button>
            <span className="margin--left--s">
              <Button onClick={handleDelete}>{t("Confirm unlink")}</Button>
            </span>
          </div>
        }
        onUserDismiss={() => setIsConfirmationModalOpen(false)}
        title={t("Remove external account?")}
      >
        <div>
          Are you sure you want to remove external account{" "}
          <b>{account.nickname || account.name}</b>? This cannot be undone.
        </div>
      </Dialog>
    </>
  );
};
Options.propTypes = {
  account: PropTypes.object.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  setIsEditingName: PropTypes.func.isRequired,
};

export default Options;
