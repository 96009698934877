import React, { useState } from "react";
import { useLocalization } from "@fluent/react";
import ApiHttp from "byzantine/src/ApiHttp";
import PropTypes from "prop-types";
import CreditCardDetector from "cleave.js/src/shortcuts/CreditCardDetector";
import { ContentCard, Toggle, Tooltip, useNotificationContext } from "cerulean";
import lockSvg from "../../assets/images/lock.svg";
import styles from "./AccountCard.module.css";

const AccountCard = (props) => {
  const { l10n } = useLocalization();
  const { clearNotifications, sendNotification } = useNotificationContext();
  const [isFrozen, setFrozen] = useState(props.state !== "active"); // get card state
  const [isFetching, setFetching] = useState(false);
  const cardSvg = window.btoa(props.svg);
  const cardType = CreditCardDetector.getInfo(props.bin).type;
  const cardProvider = cardType !== "unknown" ? cardType.toUpperCase() : "";

  const getStateChangeMsg = (state) => {
    if (state === "inactive") {
      return l10n.getString("card-state-inactive", null, "Card frozen.");
    } else {
      return l10n.getString("card-state-active", null, "Card unfrozen.");
    }
  };

  async function changeCardState() {
    clearNotifications();
    const payload = {
      last_four_digits: props.last_four_digits,
      state: !isFrozen ? "inactive" : "active", // do the opposite
    };
    ApiHttp.fetch(
      `cards/${props.id}`,
      { method: "PUT", headers: { "API-Version": "0016" } },
      payload
    )
      .then((response) => {
        const card_frozen = response.card.state !== "active";
        if (response.card) {
          setFrozen(card_frozen); // use card state from response
        }
        setFetching(false);
        sendNotification({
          type: "success",
          text: getStateChangeMsg(payload.state),
        });
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-unknown", null, "Something went wrong"),
        });
        setFrozen((currently_frozen) => !currently_frozen);
        setFetching(false);
      });
  }
  return (
    <div className={styles.accountCard}>
      <ContentCard>
        <span className="fontColor--color-grey fontWeight--semibold">
          {cardProvider}****{props.last_four_digits}
        </span>
        <div className={styles.cardContent}>
          <div className="alignChild--center--center">
            <div
              className={styles.frozenLock}
              style={{
                visibility: isFrozen ? "visible" : "hidden",
              }}
            >
              <img alt="lock" src={lockSvg} />
              <span>{l10n.getString("card-frozen", null, "Frozen")}</span>
            </div>
            <div
              className={styles.cardSvg}
              style={{
                color: props.card_text_color,
                filter: isFrozen ? "opacity(0.4)" : "",
                backgroundImage: `url('data:image/svg+xml;base64,${cardSvg}')`,
              }}
            >
              <span className={styles.firstFour}>****</span>
              <span className={styles.lastFour}>{props.last_four_digits}</span>
            </div>
          </div>
          <div className="alignChild--left--center margin--top--l">
            <div style={{ alignItems: "center", display: "flex" }}>
              <span id="freeze-label">
                {l10n.getString("label-freeze-card", null, "Lock card")}
              </span>
              <div
                style={{
                  marginLeft: "5px",
                  marginRight: "82px",
                }}
              >
                <Tooltip
                  text={l10n.getString(
                    "tooltip-frozen-card",
                    null,
                    "If locked, your card cannot be used for any new purchases/transactions."
                  )}
                  side="bottom"
                  maxWidth="267px"
                >
                  <span className="narmi-icon-info fontSize--xs"></span>
                </Tooltip>
              </div>
            </div>
            <Toggle
              labelledBy={"freeze-label"}
              isActive={isFrozen}
              onChange={() => {
                if (!isFetching) {
                  changeCardState();
                  setFetching(true);
                  setFrozen((x) => !x);
                }
              }}
            />
          </div>
        </div>
      </ContentCard>
    </div>
  );
};

AccountCard.propTypes = {
  id: PropTypes.string,
  bin: PropTypes.string,
  svg: PropTypes.string.isRequired,
  card_text_color: PropTypes.string.isRequired,
  last_four_digits: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
};

export default AccountCard;
