import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { SearchTransactionTypeOptions } from "byzantine/src/Transaction";
import Filters from "byzantine/src/filters";
import {
  Button,
  ContextForm,
  Dialog,
  Tag,
  TextInput,
  formatNumber,
  useFormData,
} from "cerulean";
import AmountTextInput from "../form/AmountTextInput";
import DateRangeFormField from "../form/DateRangeFormField";
import DropdownField from "../form/DropdownField";

export const getStartOfDay = (date) => (
  /* returns timezone-aware date at start of day */
  DateTime.fromFormat(date, "M/d/yyyy")
    .toUTC()
    .setZone("local", { keepLocalTime: true })
    .startOf("day")
    .toISO()
);

export const getEndOfDay = (date) => (
  /* returns timezone-aware date at end of day */
  DateTime.fromFormat(date, "M/d/yyyy")
    .toUTC()
    .setZone("local", { keepLocalTime: true })
    .endOf("day")
    .toISO()
);

export const presetPeriods = () => {
  /* outputs a list of options of this month, last month, the month before that, and a user-set custom date range.
     used to hydrate a dropdown field or to generate what the start and end date is for a month interval.
  */
  const currMonthEnd = DateTime.now();
  const currMonthStart = currMonthEnd.set({ day: 1 });
  const prevMonthEnd = currMonthStart.minus({ days: 1 });
  const prevMonthStart = prevMonthEnd.set({ day: 1 });
  const prevPrevMonthEnd = prevMonthStart.minus({ days: 1 });
  const prevPrevMonthStart = prevPrevMonthEnd.set({ day: 1 });

  const options = [
    [currMonthStart, currMonthEnd],
    [prevMonthStart, prevMonthEnd],
    [prevPrevMonthStart, prevPrevMonthEnd],
  ].map(([start, end]) => ({
      displayName: start.toFormat("MMMM yyyy"),
      value: [start.toFormat("MM/dd/yyyy"), end.toFormat("MM/dd/yyyy")],
    }));

  options.push({ displayName: "Custom date range", value: "custom" });

  return options;
};

export const convertFiltersToQuery = (filters) => {
  let query = "";

  if (filters?.keyword) {
    query = filters.keyword;
  }
  if (filters?.minAmount) {
    const cleanedMinAmount = filters.minAmount.replace(/[$,]/g, "");
    query = `${query} amount>="${cleanedMinAmount}"`;
  }
  if (filters?.maxAmount) {
    const cleanedMaxAmount = filters.maxAmount.replace(/[$,]/g, "");
    query = `${query} amount<="${cleanedMaxAmount}"`;
  }

  const periods = presetPeriods();
  const currentPeriod = periods.find(
    (period) => JSON.stringify(period.value) === JSON.stringify(filters?.period)
  );
  if (currentPeriod) {
    if (currentPeriod.value !== "custom") {
      const [periodStart, periodEnd] = filters.period;
      query = `${query} settled>="${getStartOfDay(
        periodStart
      )}" settled<"${getEndOfDay(periodEnd)}"`;
    } else {
      if (filters?.min_date) {
        query = `${query} settled>="${getStartOfDay(filters.min_date)}"`;
      }
      if (filters?.max_date) {
        query = `${query} settled<"${getEndOfDay(filters.max_date)}"`;
      }
    }
  }

  if (filters?.category && filters?.category !== "all") {
    query = `${query} category:"${filters.category}"`;
  }

  if (filters?.tag && filters?.tag !== "all") {
    query = `${query} tag:"${filters.tag}"`;
  }

  if (filters?.transaction_type) {
    query = `${query} is:${filters.transaction_type}`;
  }

  return query;
};

export const convertFiltersToTags = (filters) => {
  const filterTags = [];

  if (filters?.keyword) {
    filterTags.push({ type: "keyword", value: `"${filters.keyword}"` });
  }

  // if both min and max amount are set, treat as 1 filter
  if (filters?.minAmount && filters?.maxAmount) {
    const minAmount = formatNumber(
      Number(filters.minAmount.replace(/[$,]/g, ""))
    );
    const maxAmount = formatNumber(
      Number(filters.maxAmount.replace(/[$,]/g, ""))
    );
    filterTags.push({
      type: "amountRange",
      value: `${minAmount}-${maxAmount}`,
    });
  } else if (filters?.minAmount) {
    const minAmount = formatNumber(
      Number(filters.minAmount.replace(/[$,]/g, ""))
    );
    filterTags.push({ type: "minAmount", value: `${minAmount}+` });
  } else if (filters?.maxAmount) {
    const maxAmount = formatNumber(
      Number(filters.maxAmount.replace(/[$,]/g, ""))
    );
    filterTags.push({ type: "maxAmount", value: `${maxAmount}-` });
  }

  const periods = presetPeriods();
  const currentPeriod = periods.find(
    (period) => JSON.stringify(period.value) === JSON.stringify(filters?.period)
  );
  if (currentPeriod) {
    if (currentPeriod.value !== "custom") {
      // pre-set date ranges behave as 1 filter (the before & after are hardcoded for the range)
      filterTags.push({
        type: "period",
        value: `${currentPeriod.displayName}`,
      });
    } else {
      // custom date ranges behave as 2 separate filters (the min_date & max_date can be separately deleted)
      if (filters?.min_date) {
        filterTags.push({
          type: "min_date",
          value: `after: ${Filters.americanDate(
            `${DateTime.fromFormat(filters.min_date, "M/d/yyyy").toJSDate()}`
          )}`,
        });
      }
      if (filters?.max_date) {
        filterTags.push({
          type: "max_date",
          value: `before: ${Filters.americanDate(
            `${DateTime.fromFormat(filters.max_date, "M/d/yyyy").toJSDate()}`
          )}`,
        });
      }
    }
  }

  // setting category=all is the equivalent of not setting a category
  if (filters?.category && filters?.category !== "all") {
    filterTags.push({
      type: "category",
      value: `category: ${filters.category}`,
    });
  }

  // setting tag=all is the equivalent of not setting a tag
  if (filters?.tag && filters?.tag !== "all") {
    filterTags.push({ type: "tag", value: `tag: ${filters.tag}` });
  }

  if (filters?.transaction_type) {
    filterTags.push({
      type: "transaction_type",
      value: `type: ${SearchTransactionTypeOptions.find(
        (option) => option.value === filters.transaction_type
      )?.displayName.toLowerCase()}`,
    });
  }

  return filterTags;
};

export const TransactionFilterTagSection = ({
  searchFilters,
  setSearchFilters,
}) => {
  /* display active filters as deleteable tags */
  const filterArray = convertFiltersToTags(searchFilters);
  if (filterArray.length === 0) return null;

  const deleteFilter = (filterType) => {
    setSearchFilters((currentState) => {
      const updatedState = { ...currentState };

      /* `amountRange` filter type is the combination of minAmount and maxAmount fields,
         which are treated as 1 filter if they are both set */
      if (filterType === "amountRange") {
        delete updatedState.minAmount;
        delete updatedState.maxAmount;
      } else {
        delete updatedState[filterType];
      }

      // clear `period` if it is set to `custom` and both `min_date` and `max_date` have been cleared
      if (
        updatedState?.period === "custom" &&
        !updatedState?.min_date &&
        !updatedState?.max_date
      ) {
        delete updatedState.period;
      }

      return updatedState;
    });
  };

  return (
    <div className="tags-row search">
      {filterArray.map((filter) => (
        <Tag
          key={JSON.stringify(filter)}
          kind="dismissible"
          label={filter.value}
          onDismiss={() => deleteFilter(filter.type)}
        />
      ))}
      <Button
        kind="plain"
        label="Clear all"
        onClick={() => setSearchFilters({})}
      />
    </div>
  );
};
TransactionFilterTagSection.propTypes = {
  searchFilters: PropTypes.object.isRequired,
  setSearchFilters: PropTypes.func.isRequired,
};

const MinMaxAmountField = () => (
    <div style={{ display: "flex" }}>
      <ContextForm.Field>
        <AmountTextInput
          field="minAmount"
          label="Min"
          data-test-element="min-amount-input"
        />
      </ContextForm.Field>
      <div
        className="margin--x--xs fontSize--s margin--bottom--l"
        style={{ display: "flex" }}
      >
        <div style={{ margin: "auto" }}>to</div>
      </div>
      <ContextForm.Field>
        <AmountTextInput
          field="maxAmount"
          label="Max"
          data-test-element="max-amount-input"
        />
      </ContextForm.Field>
    </div>
  );

const SearchAndFilterForm = ({
  closeDialog,
  searchFilters,
  setSearchFilters,
  switchToAllTransactionsTab,
  searchableCategories,
  searchableTags,
}) => {
  const { formData, setFormData, onChange } = useFormData(searchFilters);

  // organize categories & tags into the form that Dropdown expects its options to be in
  const categoryOptions = searchableCategories.map((category) => ({ displayName: category, value: category }));
  categoryOptions.unshift({ displayName: "All categories", value: "all" });

  const tagOptions = searchableTags.map((tag) => ({ displayName: tag, value: tag }));
  tagOptions.unshift({ displayName: "All tags", value: "all" });

  useEffect(() => {
    if (formData?.period !== "custom") {
      setFormData((prevState) => {
        const newState = { ...prevState };
        delete newState.min_date;
        delete newState.max_date;
        return newState;
      });
    }
  }, [formData?.period]);

  const clearForm = () => {
    // updates the search filters for the parent to also be `{}` if it wasn't empty before
    setFormData({});
    if (JSON.stringify(searchFilters) !== "{}") setSearchFilters({});
  };

  const submitSearchAndFilterForm = () => {
    setSearchFilters(formData);
    /* After the user submits the search & filter form, the active tab will be switched to `All`.
       This is because `Recent` tab's transactions will not reflect the filtering.
     */
    switchToAllTransactionsTab();
    closeDialog();
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field>
        <DropdownField
          options={presetPeriods()}
          field="period"
          label="Date range"
        />
      </ContextForm.Field>
      <DateRangeFormField hidden={formData?.period !== "custom"} />
      <div className="search-and-filter-border">
        <MinMaxAmountField />
      </div>
      <div className="search-and-filter-border">
        <ContextForm.Field>
          <TextInput
            name="keyword"
            field="keyword"
            label="Keyword"
            data-test-element="input-keyword"
          />
        </ContextForm.Field>
      </div>
      {categoryOptions.length > 1 && (
        <div className="search-and-filter-border">
          <ContextForm.Field>
            <DropdownField
              options={categoryOptions}
              field="category"
              label="Category"
            />
          </ContextForm.Field>
        </div>
      )}
      <div className="search-and-filter-border">
        <ContextForm.Field>
          <DropdownField
            options={SearchTransactionTypeOptions}
            field="transaction_type"
            label="Transaction type"
          />
        </ContextForm.Field>
      </div>
      {tagOptions.length > 1 && (
        <div className="search-and-filter-border">
          <ContextForm.Field>
            <DropdownField options={tagOptions} field="tag" label="Tag" />
          </ContextForm.Field>
        </div>
      )}
      <ContextForm.ActionBar>
        <ContextForm.Action onSubmit={clearForm} dangerouslyDisableShowLoading>
          <Button type="button" kind="plain" label="Clear all" />
        </ContextForm.Action>
        <ContextForm.Action
          onSubmit={submitSearchAndFilterForm}
          dangerouslyDisableShowLoading
        >
          <div style={{ marginLeft: "auto" }}>
            <Button
              kind="primary"
              label="Show results"
              data-test-element="button-show-results"
            />
          </div>
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};
SearchAndFilterForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
  setSearchFilters: PropTypes.func.isRequired,
  switchToAllTransactionsTab: PropTypes.func.isRequired,
  searchableCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const TransactionSearchAndFilter = ({
  searchFilters,
  setSearchFilters,
  switchToAllTransactionsTab,
  searchableCategories,
  searchableTags,
}) => {
  /* Necessary wrapper because if the search filter dialog state was kept inside of `TransactionCard`,
     the paginated transactions list would refresh every time the dialog state changed
     (i.e. when opening or closing) */
  const filterLength = convertFiltersToTags(searchFilters).length;
  const [isSearchFilterOpen, setIsSearchFilterOpen] = useState(false);
  const closeDialog = () => setIsSearchFilterOpen(false);
  const openDialog = () => setIsSearchFilterOpen(true);

  return (
    <>
      <Button
        kind="plain"
        label={`Search & filter${filterLength ? ` (${filterLength})` : ""}`}
        onClick={openDialog}
        data-test-element="button-search-filter"
      />
      <Dialog
        isOpen={isSearchFilterOpen}
        onUserDismiss={closeDialog}
        title={`Search & filter`}
      >
        <div className="margin--top--s">
          <SearchAndFilterForm
            closeDialog={closeDialog}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            switchToAllTransactionsTab={switchToAllTransactionsTab}
            searchableTags={searchableTags}
            searchableCategories={searchableCategories}
          />
        </div>
      </Dialog>
    </>
  );
};
TransactionSearchAndFilter.propTypes = {
  setSearchFilters: PropTypes.func.isRequired,
  searchFilters: PropTypes.object.isRequired,
  switchToAllTransactionsTab: PropTypes.func.isRequired,
  searchableCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchableTags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TransactionSearchAndFilter;
