import React from "react";
import PropTypes from "prop-types";

import { ContentCard, Toggle } from "@narmi/design_system";
import ReactMarkdown from "react-markdown";

const PreferenceContentCard = ({
  title,
  subtitle,
  subtext,
  onChange = null,
  content = null,
  showToggle = true,
  deactivateToggle = false,
  headerButton = null,
  showSubtext = true,
  renderSubtextAsMarkdown = false,
}) => {
  /*
  If using this component outside of azul or AO, make sure copy over the css styles
  TODO: update these styles to cerulean
  */
  return (
    <ContentCard className="preference-card" kind="elevated">
      <div className="title-container">
        <h4 className="nds-sans fontSize--heading5">{title}</h4>
        {showToggle && deactivateToggle && (
          <div className="toggle-container">
            <Toggle
              onChange={onChange}
              defaultActive
              isActive
              enabledLabel="enabled"
              disabledLabel="disabled"
            />
          </div>
        )}
        {showToggle && !deactivateToggle && (
          <div className="toggle-container">
            <Toggle
              onChange={onChange}
              enabledLabel="enabled"
              disabledLabel="disabled"
            />
          </div>
        )}
        {headerButton}
      </div>
      <div>{subtitle}</div>
      {content}
      {showSubtext && (
        <>
          <hr className="preference-card-divider" />
          {renderSubtextAsMarkdown ? (
            <ReactMarkdown className="subtext" linkTarget="_blank">
              {subtext}
            </ReactMarkdown>
          ) : (
            <div className="subtext">{subtext}</div>
          )}
        </>
      )}
    </ContentCard>
  );
};
PreferenceContentCard.propTypes = {
  headerButton: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.object,
  deactivateToggle: PropTypes.bool,
  content: PropTypes.element,
  showSubtext: PropTypes.bool,
  subtext: PropTypes.object,
  onChange: PropTypes.func,
};

export default PreferenceContentCard;
