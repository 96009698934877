import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "cerulean";

const AmountSavedCard = ({ hasBeenEnrolled, totalSaved }) => (
  <div className="amount-saved-card-wrapper">
    {hasBeenEnrolled ? (
      <>
        <div
          className="fontSize--l margin--bottom--m"
          data-testid="saved-text"
        >{`You saved:`}</div>
        <h1 data-testid="saved-amount-text">{`${formatNumber(
          totalSaved / 100
        )}`}</h1>
      </>
    ) : (
      <>
        <h4>{`Turn on Automatic Savings to save 3% from each deposit`}</h4>
        <div className="margin--top--m">
          {`On average, Automatic Savings users save an extra $1695.48 per year!`}
        </div>
      </>
    )}
  </div>
);
AmountSavedCard.propTypes = {
  hasBeenEnrolled: PropTypes.bool.isRequired,
  totalSaved: PropTypes.number,
};
export default AmountSavedCard;
