import React from "react";
import PropTypes from "prop-types";
import Wire from "byzantine/src/Wire";
import Institution from "byzantine/src/Institution";
import Address from "byzantine/src/Address";
import ApiHttp from "byzantine/src/ApiHttp";
import { ContextForm, useFormData, useNotificationContext } from "cerulean";
import useReviewableAction from "../useReviewableAction";
import WireTransferForm from "./WireTransferForm";
import WireTransferActionReview from "./WireTransferActionReview";
import SudoAction from "../SudoAction";
import TfaVerify from "../verify/TfaVerify";
import useActions from "../hooks/useActions";

const errorIsElevatedAuthError = (error) =>
  error === "Elevated authentication required to confirm this action";

const WireTransfer = ({ limits }) => {
  const { formData, onChange } = useFormData({ beneficiary_address_2: "" });
  const reviewableAction = useReviewableAction();
  const { goToPrevious } = useActions();
  const { sendNotification } = useNotificationContext();

  const onSubmit = (callback) => {
    // Our custom AddressField component uses street_address and street_address_2, so we need to set
    // beneficiary_address_1 and beneficiary_address_2 values on the formData object before creating
    // the Wire object.
    formData.beneficiary_address_1 = formData.street_address;
    formData.beneficiary_address_2 = formData.street_address_2;
    const wire = new Wire(formData);
    wire
      .submit()
      .then((response) => {
        let success_message = "success=Your+wire+has+been+sent.";
        if (response.wires[0].state === "awaiting_approval") {
          success_message =
            "success=Your+wire+has+been+submitted+and+is+awaiting+approval.";
        }
        goToPrevious(success_message);
      })
      .catch((error) => {
        if (errorIsElevatedAuthError(error)) {
          callback();
          reviewableAction.goToSudo();
          return;
        }
        if (typeof error === "string") {
          goToPrevious(`negative=${encodeURIComponent(error)}`);
        } else {
          callback(error);
          reviewableAction.goToInput();
        }
      });
  };

  const checkSudo = async () => {
    // Cerulean's AddressField does not use ContextForm so we need to validate that all
    // required fields are present before moving onto review.
    const address = new Address(formData);
    try {
      address.validate();
    } catch (error) {
      sendNotification({
        type: "negative",
        text: "Please enter a complete address.",
      });
      return;
    }
    const institution = new Institution();
    const settings = await institution.getSettings();
    if (!settings.sudo_mode_required_for_wires) {
      reviewableAction.goToReview();
      return;
    }
    ApiHttp.fetch("sudo")
      .then(() => reviewableAction.goToReview())
      .catch(() => reviewableAction.goToSudo());
  };

  return (
    <ContextForm nativeForm={false} data={formData} onChange={onChange}>
      <SudoAction
        reviewableAction={reviewableAction}
        renderInput={
          <div className="transfer-page-layout">
            <div className="transfer-page">
              <WireTransferForm
                data={formData}
                onSubmit={checkSudo}
                cancel={goToPrevious}
                limits={limits}
                onChange={onChange}
              />
            </div>
          </div>
        }
        renderSudo={
          <TfaVerify
            goToReview={reviewableAction.goToReview}
            cancelAction={() => goToPrevious()}
          />
        }
        renderReview={
          <div className="transfer-page-layout">
            <div className="transfer-page">
              <WireTransferActionReview
                data={formData}
                onSubmit={onSubmit}
                goBack={reviewableAction.goToInput}
                cancel={goToPrevious}
              />
            </div>
          </div>
        }
      />
    </ContextForm>
  );
};

WireTransfer.propTypes = {
  limits: PropTypes.object,
};

export default WireTransfer;
