import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { Drawer as NdsDrawer } from '@narmi/design_system';
// TODO eventually put all modal logic together?
var setGliaVisibility = function (visibility) {
    var style = "display: none".concat(visibility ? '' : ' !important');
    if (document.querySelector('#salemove')) {
        document.querySelector('#salemove').setAttribute('style', style);
    }
};
var Drawer = function (_a) {
    var isOpen = _a.isOpen, onUserDismiss = _a.onUserDismiss, onNext = _a.onNext, onPrev = _a.onPrev, showClose = _a.showClose, showControls = _a.showControls, position = _a.position, depth = _a.depth, paddingSize = _a.paddingSize, testId = _a.testId, children = _a.children;
    useEffect(function () {
        setGliaVisibility(!isOpen);
        return function () {
            setGliaVisibility(true);
        };
    }, [isOpen]);
    return _jsx(NdsDrawer, { isOpen: isOpen, onUserDismiss: onUserDismiss, onNext: onNext, onPrev: onPrev, showClose: showClose, showControls: showControls, position: position, depth: depth, paddingSize: paddingSize, testId: testId, children: children });
};
export default Drawer;
