import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button } from "@narmi/design_system";
import { ContextForm } from "cerulean";
import AccountContext from "../contexts/AccountContext";
import ActionReview from "./ActionReview";
import { computeAccountNumberDisplayValue } from "../form/AccountNumberTextInput";

const WireTransferActionReview = ({ data, onSubmit, goBack, cancel }) => {
  const { accounts } = React.useContext(AccountContext);
  const { t } = useTranslation();

  const wireDetailsItems = [
    {
      label: "From",
      info: accounts.find((a) => a.id === data.from_account).getDescription(),
    },
    { label: "Amount", info: `${data.amount}` },
    { label: t("Memo"), info: data.memo_1 },
  ];

  if (data.memo_2 !== undefined) {
    wireDetailsItems.push({ label: t("Memo Line 2"), info: data.memo_2 });
  }

  if (data.memo_3 !== undefined) {
    wireDetailsItems.push({ label: t("Memo Line 3"), info: data.memo_3 });
  }

  if (data.memo_4 !== undefined) {
    wireDetailsItems.push({ label: t("Memo Line 4"), info: data.memo_4 });
  }

  const details = [
    {
      sectionLabel: "Wire details",
      items: wireDetailsItems,
    },
    {
      sectionLabel: "Recipient details",
      items: [
        { label: "Name", info: data.beneficiary_name },
        {
          label: "Address",
          info: `${data.street_address}${
            data.street_address_2 ? ` ${data.street_address_2}` : ""
          }, ${data.city}, ${data.region_code} ${data.postal_code}`,
        },
        {
          label: "Routing number",
          info: data.to_account_routing_number || "",
        },
        {
          label: "Account number",
          info: computeAccountNumberDisplayValue(data.to_account_number),
        },
      ],
    },
  ];

  return (
    <ActionReview
      details={details}
      icon="wires"
      title="Review your wire"
      goBack={goBack}
    >
      <ContextForm.ActionBar vertical>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button label="Send wire" />
        </ContextForm.Action>
        <div className="transfer-cancel-button">
          <Button
            onClick={() => cancel()}
            kind="negative"
            type="button"
            label="Cancel"
          />
        </div>
      </ContextForm.ActionBar>
    </ActionReview>
  );
};
WireTransferActionReview.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func,
  cancel: PropTypes.func,
};

export default WireTransferActionReview;
