import React from "react";
import Organization from "byzantine/src/Organization";
import OrganizationUser from "byzantine/src/OrganizationUser";
import { NotificationContext } from "cerulean";

const DualApprovalNotificationBar = () => {
  /* sends an info toast about outstanding dual approvals if they exist */
  const { sendNotification } = React.useContext(NotificationContext);
  const [numRequests, setNumRequests] = React.useState(0);

  React.useEffect(() => {
    const orgUser = OrganizationUser.currentOrgUserFromHTML();
    if (!orgUser.uuid) return;
    Organization.getNumberOfDualApprovalRequests()
      .then((response) => setNumRequests(response))
      .catch(() => setNumRequests(0));
  }, []);

  React.useEffect(() => {
    if (numRequests) {
      const pluralization = numRequests > 1 ? "approvals" : "approval";
      sendNotification({
        type: "info",
        text: `You have ${numRequests} pending ${pluralization}.  \n[Review now](/approval_requests)`,
      });
    }
  }, [numRequests]);

  return null;
};

export default DualApprovalNotificationBar;
