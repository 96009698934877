import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ContentCard, IconButton } from "cerulean";
import { Link } from "react-router-dom";
import { useLocalization } from "@fluent/react";
import styles from "./ReplaceCard.module.scss";
var ReplaceCard = function (_a) {
    var cardId = _a.cardId;
    var l10n = useLocalization().l10n;
    return _jsx(Link, { to: "/settings/cards/replace-a-card/".concat(cardId), className: styles.replaceCardContainer, children: _jsxs(ContentCard, { kind: "elevated", children: [_jsxs("div", { className: styles.titleContainer, children: [_jsx("span", { className: styles.title, children: l10n.getString("card-feature-replace") }), _jsx("span", { "aria-hidden": "true", className: styles.iconContainer, children: _jsx(IconButton, { name: "arrow-right", kind: "plain", textSize: "l" }) })] }), _jsx("p", { className: "fontColor--secondary fontSize--s ".concat(styles.description), children: l10n.getString("card-feature-replace-description") })] }) });
};
export default ReplaceCard;
