import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import ApiHttp from "byzantine/src/ApiHttp";
import Filters from "byzantine/src/filters";
import {
  TextInput,
  Row,
  ContentCard,
  Button,
  LoadingContext,
  NotificationContext,
} from "cerulean";

import Options from "./Options";
import VerifyAccount from "./VerifyAccount";

const ExternalAccount = ({
  account,
  editOrDeleteAccount,
  updateAccounts,
  uatDeposits,
}) => {
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState(
    account.nickname || account.name
  );
  const { setIsLoading } = useContext(LoadingContext);
  const { sendNotification } = useContext(NotificationContext);

  const handleEdit = () => {
    setIsLoading(true);
    const payload = { name: editedName };

    ApiHttp.fetch(
      `accounts/${account.id}`,
      {
        method: "PUT",
      },
      payload
    )
      .then(() => {
        setIsLoading(false);
        setIsEditingName(false);
        editOrDeleteAccount(account.id, editedName);
      })
      .catch((err) => {
        setIsLoading(false);
        sendNotification({
          type: "negative",
          text: err,
        });
      });
  };

  return (
    <ContentCard>
      <Row gapSize="xs" alignItems="center">
        {isEditingName ? (
          <>
            <Row.Item>
              <TextInput
                onChange={(event) => setEditedName(event.target.value)}
                defaultValue={account.nickname || account.name}
              />
            </Row.Item>
            <div
              className="alignChild--right--center"
              style={{ gap: "inherit" }}
            >
              {" "}
              <Row.Item shrink>
                <Button
                  kind="negative"
                  label="Cancel"
                  size="xs"
                  onClick={() => setIsEditingName(false)}
                />
              </Row.Item>
              <Row.Item shrink>
                <Button
                  kind="primary"
                  label="Confirm"
                  size="xs"
                  onClick={handleEdit}
                />
              </Row.Item>
            </div>
          </>
        ) : (
          <Row.Item>
            <div className="fontWeight--semibold">
              {account.nickname || account.name}
            </div>
            <div className="fontSize--xs">
              <span style={{ textTransform: "capitalize" }}>
                {account.product_type}
              </span>
              <span className="fontColor--hint">
                {" "}
                | {account.verified ? "Verified" : "Pending"} on{" "}
                {Filters.longMonthDayYear(account.created_at)}
              </span>
            </div>
          </Row.Item>
        )}

        {!account.verified && !isEditingName && (
          <Row.Item shrink>
            <VerifyAccount
              account={account}
              uatDeposits={uatDeposits}
              updateAccounts={updateAccounts}
            />
          </Row.Item>
        )}
        <Row.Item shrink>
          <Options
            account={account}
            deleteAccount={editOrDeleteAccount}
            setIsEditingName={setIsEditingName}
          />
        </Row.Item>
      </Row>
    </ContentCard>
  );
};
ExternalAccount.propTypes = {
  account: PropTypes.object.isRequired,
  editOrDeleteAccount: PropTypes.func.isRequired,
  updateAccounts: PropTypes.func.isRequired,
  uatDeposits: PropTypes.object.isRequired,
};

export default ExternalAccount;
