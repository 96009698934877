/**
 * # Fluent id helpers for institution settings
 *
 * @description
 * All id getters defined in this file match the ids in the output of
 * `to_fluent_messages` setting classmethods.
 *
 * @usage
 * For string type settings, use `getStringSettingFluentId()`.
 *
 * For JSON type settings, one class per setting has id getters.
 */
export var FLUENT_ID_PREFIX = "fi"; // used for all settings
/**
 * @param settingName SETTING_NAME field of setting as hyphenated lowercase
 */
export var getStringSettingFluentId = function (settingName) {
    return "".concat(FLUENT_ID_PREFIX, "-").concat(settingName);
};
/**
 * @description Get translation ids by product id and field
 * @usage
 * ```
 * const CheckingFluentIds = new ProductFluentIds(1234);
 * CheckingFluentIds.getName(); // product name
 * CheckingFluentIds.getDetail(2); // detail by index
 * ```
 */
var ProductFluentIds = /** @class */ (function () {
    function ProductFluentIds(productId) {
        this.SETTING_NAME = "core-opening-products";
        this.productId = productId;
    }
    ProductFluentIds.prototype.getName = function () {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME, "-").concat(this.productId, "-name");
    };
    ProductFluentIds.prototype.getDescription = function () {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME, "-").concat(this.productId, "-description");
    };
    ProductFluentIds.prototype.getDetail = function (detailIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME, "-").concat(this.productId, "-details").concat(detailIndex);
    };
    ProductFluentIds.prototype.getDisclosureCheckboxText = function (disclosureIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME, "-").concat(this.productId, "-disclosures").concat(disclosureIndex, "-checkbox-text");
    };
    ProductFluentIds.prototype.getDisclosureMarkdown = function (disclosureIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME, "-").concat(this.productId, "-disclosures").concat(disclosureIndex, "-markdown");
    };
    ProductFluentIds.prototype.getDisclosureLinkText = function (disclosureIndex, linkIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME, "-").concat(this.productId, "-disclosures").concat(disclosureIndex, "-links").concat(linkIndex, "-text");
    };
    return ProductFluentIds;
}());
export { ProductFluentIds };
/**
 * @usage
 * ```
 * const fluentIds = new DueDiligenceFluentIds("bao");
 * DueDiligenceFluentIds.getQuestion(0); // first due diligence question in BAO
 * ```
 */
var DueDiligenceFluentIds = /** @class */ (function () {
    function DueDiligenceFluentIds(aoType) {
        this.SETTING_NAME = "cdd-".concat(aoType, "-questions");
    }
    DueDiligenceFluentIds.prototype.getQuestion = function (questionIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(questionIndex);
    };
    DueDiligenceFluentIds.prototype.getQuestionLabel = function (questionIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(questionIndex, "-label");
    };
    DueDiligenceFluentIds.prototype.getDropdownQuestionOption = function (questionIndex, optionIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(questionIndex, "-options").concat(optionIndex);
    };
    return DueDiligenceFluentIds;
}());
export { DueDiligenceFluentIds };
var SupportContactFluentIds = /** @class */ (function () {
    function SupportContactFluentIds() {
        this.SETTING_NAME = "support-contacts";
    }
    SupportContactFluentIds.prototype.getTitle = function (contactIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(contactIndex, "-title");
    };
    SupportContactFluentIds.prototype.getNumberName = function (contactIndex, numberIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(contactIndex, "-numbers").concat(numberIndex, "-name");
    };
    return SupportContactFluentIds;
}());
export { SupportContactFluentIds };
var SupportFaqFluentIds = /** @class */ (function () {
    function SupportFaqFluentIds() {
        this.SETTING_NAME = "support-faq";
    }
    SupportFaqFluentIds.prototype.getCategory = function (categoryIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(categoryIndex, "-category");
    };
    SupportFaqFluentIds.prototype.getQuestion = function (categoryIndex, questionIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(categoryIndex, "-questions").concat(questionIndex, "-question");
    };
    SupportFaqFluentIds.prototype.getAnswer = function (categoryIndex, questionIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(categoryIndex, "-questions").concat(questionIndex, "-answer");
    };
    return SupportFaqFluentIds;
}());
export { SupportFaqFluentIds };
var SupportLinksFluentIds = /** @class */ (function () {
    function SupportLinksFluentIds() {
        this.SETTING_NAME = "support-links";
    }
    SupportLinksFluentIds.prototype.getText = function (linkIndex) {
        return "".concat(FLUENT_ID_PREFIX, "-").concat(this.SETTING_NAME).concat(linkIndex, "-text");
    };
    return SupportLinksFluentIds;
}());
export { SupportLinksFluentIds };
