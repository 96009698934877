import React from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import { TextInput, Button } from "@narmi/design_system";
import { ContextForm, RoutingNumberTextInput } from "cerulean";
import AmountTextInput from "../form/AmountTextInput";
import AccountNumberTextInput from "../form/AccountNumberTextInput";
import AccountContext from "../contexts/AccountContext";
import ActionHeader from "./ActionHeader";
import LimitsDialog from "./LimitsDialog";
import AccountSelector from "../AccountSelector";
import BankingUSAddressField from "../address/BankingUSAddressField";

const WireTransferForm = ({
  data,
  onSubmit,
  cancel,
  limits = {},
  onChange,
}) => {
  const { accounts } = React.useContext(AccountContext);
  const { l10n } = useLocalization();

  const limitsForDisplay = {}; // amount and available amount per time period for each transaction type
  Object.keys(limits).forEach((transaction_type) => {
    if (!["wire", "organization_user_wire"].includes(transaction_type)) return; // only want wire lmits here
    limitsForDisplay[transaction_type] = {};

    Object.keys(limits[transaction_type]).forEach((key) => {
      // limits[transaction] has numbers (which represent days) and "amount_available" as keys
      if (Number(key)) {
        // only want number keys for display
        limitsForDisplay[transaction_type][key] = limits[transaction_type][key];
      }
    });
  });

  return (
    <div className="form-card-layout">
      <div className="card">
        <ActionHeader
          title={l10n.getString("heading-send-wire", null, "Send a wire")}
          icon="wires"
        />
        <div className="form-section-header">
          {l10n.getString("heading-wire-details", null, "Wire details")}
        </div>
        <ContextForm.Field required>
          <AccountSelector
            field="from_account"
            label={l10n.getString("label-from", null, "From")}
            accounts={accounts.filter(
              (a) => a.isInternal() && a.isValidTransferSource()
            )}
            showAddExternalAccountLink={false}
          />
        </ContextForm.Field>
        <ContextForm.Field required>
          <AmountTextInput
            field="amount"
            label={l10n.getString("label-amount", null, "Amount")}
          />
        </ContextForm.Field>
        <ContextForm.Field required style={{ marginBottom: "0px" }}>
          <TextInput
            field="memo_1"
            label={l10n.getString("label-memo", null, "Memo")}
            maxLength="35"
          />
        </ContextForm.Field>
        {data.memo_1 !== undefined && (
          <ContextForm.Field style={{ marginTop: "20px" }}>
            <TextInput
              field="memo_2"
              label={l10n.getString(
                "label-memo-line",
                { numMemoLine: 2 },
                "Memo Line 2"
              )}
              maxLength="35"
            />
          </ContextForm.Field>
        )}
        {data.memo_2 !== undefined && (
          <ContextForm.Field style={{ marginTop: "20px" }}>
            <TextInput
              field="memo_3"
              label={l10n.getString(
                "label-memo-line",
                { numMemoLine: 3 },
                "Memo Line 3"
              )}
              maxLength="35"
            />
          </ContextForm.Field>
        )}
        {data.memo_3 !== undefined && (
          <ContextForm.Field style={{ marginTop: "20px" }}>
            <TextInput
              field="memo_4"
              label={l10n.getString(
                "label-memo-line",
                { numMemoLine: 4 },
                "Memo Line 4"
              )}
              maxLength="35"
            />
          </ContextForm.Field>
        )}
        <div className="form-section-header" style={{ marginTop: "40px" }}>
          {l10n.getString("recipient-details", null, "Recipient details")}
        </div>
        <ContextForm.Field required>
          <TextInput
            field="beneficiary_name"
            label={l10n.getString("label-name", null, "Name")}
          />
        </ContextForm.Field>
        <BankingUSAddressField
          onUpdate={onChange}
          data={data || {}}
          onChange={onChange}
          showTitle={false}
          marginBottom="margin--bottom--l"
        />
        <ContextForm.Field
          required
          validate={(val) => {
            if (!val)
              return l10n.getString(
                "error-required",
                null,
                "Must be filled in"
              );
            if (val.length !== 9)
              return l10n.getString(
                "error-invalid-routing",
                null,
                "Routing number must be 9 digits"
              );
            return "";
          }}
        >
          <RoutingNumberTextInput
            field="to_account_routing_number"
            label={l10n.getString("label-routing", null, "Routing number")}
          />
        </ContextForm.Field>
        <ContextForm.Field required>
          <AccountNumberTextInput
            field="to_account_number"
            label={l10n.getString("labelAccountNumber", null, "Account number")}
          />
        </ContextForm.Field>
        <LimitsDialog isWire={true} limitsForDisplay={limitsForDisplay} />
        <ContextForm.ActionBar vertical>
          <ContextForm.Action onSubmit={onSubmit} dangerouslyDisableShowLoading>
            <Button
              label={l10n.getString("button-continue", null, "Continue")}
            />
          </ContextForm.Action>
          <div className="transfer-cancel-button">
            <Button
              onClick={() => cancel()}
              kind="negative"
              type="button"
              label={l10n.getString("button-cancel", null, "Cancel")}
            />
          </div>
        </ContextForm.ActionBar>
      </div>
    </div>
  );
};
WireTransferForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
  cancel: PropTypes.func,
  limits: PropTypes.object,
  onChange: PropTypes.func,
};

export default WireTransferForm;
