import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cc from "classcat";
import { ContentCard, Button, Alert, useBreakpoints } from "cerulean";
import { useLocalization } from "@fluent/react";
import CardImage from "../CardImage";
import styles from "./BankCard.module.scss";
var ManageButton = function (_a) {
    var onManageClick = _a.onManageClick;
    return (_jsx("div", { className: styles.manageButton, children: _jsx(Button, { kind: "plain", ariaLabel: "manage your card", onClick: onManageClick, children: "Manage" }) }));
};
var ShippingInfo = function () {
    var l10n = useLocalization().l10n;
    return (_jsx("div", { className: styles.cardShippingInfo, children: _jsx(Alert, { isActive: true, isDismissable: false, kind: "info", children: l10n.getString("card-controls-shipping-description") }) }));
};
var BankCard = function (_a) {
    var textColor = _a.textColor, isLocked = _a.isLocked, image = _a.image, name = _a.name, lastFourDigits = _a.lastFourDigits, network = _a.network, state = _a.state, onManageClick = _a.onManageClick;
    var _b = useBreakpoints(), s = _b.s, m = _b.m, l = _b.l, xl = _b.xl;
    var sOrLess = (s && !m) || (!s && !m && !l && !xl);
    return (_jsx(ContentCard, { kind: "elevated", children: _jsxs("div", { className: styles.card, children: [_jsxs("div", { className: styles.cardMainContent, children: [_jsx("div", { style: { width: 150 }, children: _jsx(CardImage, { textColor: textColor, isLocked: isLocked, image: image, lastFourDigits: lastFourDigits, network: network }) }), _jsxs("div", { className: styles.cardDetails, children: [_jsxs("div", { className: styles.cardNameAndManageButton, children: [_jsx("div", { className: cc([styles.cardName, "fontWeight--semibold"]), children: name }), m && _jsx(ManageButton, { onManageClick: onManageClick })] }), _jsxs("div", { children: ["** ", lastFourDigits] }), sOrLess && _jsx(ManageButton, { onManageClick: onManageClick }), m && state === "shipped" && _jsx(ShippingInfo, {})] })] }), sOrLess && state === "shipped" && _jsx(ShippingInfo, {})] }) }));
};
export default BankCard;
