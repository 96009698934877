var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLibrary } from "../../providers/useLibrary.hook";
import { useObjectMemo, useReferenceTo } from "../../hooks";
import requestCache from "../requestCache/request.cache";
import { REQUEST_STATUS } from "./request.types";
import { useErrorHandling } from "./errors";
var noop = function () { return null; };
var EMPTY = {};
var useRequest = function () {
    var _a = __read(useState({
        loading: false,
        data: EMPTY,
        error: null,
        status: REQUEST_STATUS.idle,
    }), 2), state = _a[0], setState = _a[1];
    var updateState = useCallback(function (patch) {
        setState(function (current) { return (__assign(__assign({}, current), patch)); });
    }, []);
    var start = useCallback(function () {
        updateState({
            status: REQUEST_STATUS.loading,
            data: EMPTY,
            loading: true,
        });
    }, [updateState]);
    var receive = useCallback(function (d) {
        updateState({
            status: REQUEST_STATUS.success,
            data: d,
        });
    }, [updateState]);
    var fail = useCallback(function (e) {
        updateState({
            status: REQUEST_STATUS.error,
            error: e,
        });
    }, [updateState]);
    var finish = useCallback(function () { return updateState({ loading: false }); }, [updateState]);
    return useObjectMemo({ state: state, start: start, receive: receive, fail: fail, finish: finish });
};
var useMessaging = function () {
    var throwToast = useLibrary("toasts").throwToast;
    var config = useRef();
    var _a = __read(useState(""), 2), successMessage = _a[0], setSuccessMessage = _a[1];
    var _b = __read(useState(""), 2), errorMessage = _b[0], setErrorMessage = _b[1];
    var initialize = useCallback(function (options) {
        setSuccessMessage("");
        setErrorMessage("");
        config.current = options;
    }, []);
    var success = useCallback(function () {
        var _a, _b;
        var options = config.current;
        var toastSuccessMessage = (_a = options === null || options === void 0 ? void 0 : options.toast) === null || _a === void 0 ? void 0 : _a.success;
        if (toastSuccessMessage) {
            throwToast({
                kind: "success",
                message: toastSuccessMessage,
            });
        }
        var textSuccessMessage = (_b = options === null || options === void 0 ? void 0 : options.text) === null || _b === void 0 ? void 0 : _b.success;
        if (textSuccessMessage) {
            setSuccessMessage(textSuccessMessage);
        }
    }, [throwToast]);
    var error = useCallback(function () {
        var _a, _b;
        var options = config.current;
        var toastErrorMessage = (_a = options === null || options === void 0 ? void 0 : options.toast) === null || _a === void 0 ? void 0 : _a.error;
        if (toastErrorMessage) {
            throwToast({
                kind: "error",
                message: toastErrorMessage,
            });
        }
        var textErrorMessage = (_b = options === null || options === void 0 ? void 0 : options.text) === null || _b === void 0 ? void 0 : _b.error;
        if (textErrorMessage) {
            setErrorMessage(textErrorMessage);
        }
    }, [throwToast]);
    var partial = useCallback(function () {
        var _a, _b;
        var options = config.current;
        var toastPartialMessage = (_a = options === null || options === void 0 ? void 0 : options.toast) === null || _a === void 0 ? void 0 : _a.partial;
        if (toastPartialMessage) {
            throwToast({
                kind: "info",
                message: toastPartialMessage,
            });
        }
        var textPartialMessage = (_b = options === null || options === void 0 ? void 0 : options.text) === null || _b === void 0 ? void 0 : _b.partial;
        if (textPartialMessage) {
            throwToast({
                kind: "info",
                message: textPartialMessage,
            });
        }
    }, [throwToast]);
    var state = useObjectMemo({ successMessage: successMessage, errorMessage: errorMessage });
    return useObjectMemo({ state: state, initialize: initialize, success: success, partial: partial, error: error });
};
// ToDo add params for polling, timeout, etc.
var useRequestWithMessaging = function (handleError) {
    var fetchApi = useLibrary("network").fetchApi;
    var request = useRequest();
    var messaging = useMessaging();
    var start = request.start, receive = request.receive, fail = request.fail, finish = request.finish, loading = request.state.loading;
    var initialize = messaging.initialize, success = messaging.success, error = messaging.error;
    var loadingRef = useReferenceTo(loading);
    var dbblConfig = useLibrary("config");
    var send = useCallback(function (props) {
        if (loadingRef.current) {
            return Promise.resolve();
        }
        var _a = props.action, url = _a.url, _b = _a.options, options = _b === void 0 ? {} : _b, optimistic = props.optimistic, _c = props.onData, onData = _c === void 0 ? noop : _c, _d = props.onSuccess, onSuccess = _d === void 0 ? noop : _d, _e = props.onError, onError = _e === void 0 ? noop : _e, _f = props.onFinally, onFinally = _f === void 0 ? noop : _f;
        initialize(props.messaging);
        start();
        optimistic === null || optimistic === void 0 ? void 0 : optimistic.update();
        var sendRequest = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchApi(url, options)];
                    case 1:
                        response = _a.sent();
                        // Check strict equality, must intentionally specify "false" if no JSON otherwise default true
                        // @TODO Promise.resolve() returns Promise<D>, we specifically want to return undefined if no data #33279
                        return [2 /*return*/, options.isJsonResponse === false
                                ? Promise.resolve()
                                : response === null || response === void 0 ? void 0 : response.json()];
                }
            });
        }); };
        var response;
        var cacheKey = requestCache.getCacheKey(url, options);
        var existingResponse = requestCache.checkCache(cacheKey, dbblConfig.network.cache.ttlMilliseconds);
        if (existingResponse === false) {
            response = sendRequest();
            requestCache.addItem(cacheKey, response);
        }
        else {
            response = existingResponse;
        }
        response
            .catch(function (err) {
            if (handleError) {
                return handleError(err, props, sendRequest);
            }
            throw err;
        })
            .then(function (d) {
            onData(d);
            receive(d);
            success();
            onSuccess();
        })
            .catch(function (err) {
            fail(err);
            optimistic === null || optimistic === void 0 ? void 0 : optimistic.rollback();
            error();
            onError(err);
        })
            .finally(function () {
            finish();
            onFinally();
        });
        return response;
    }, [
        loadingRef,
        initialize,
        start,
        fetchApi,
        receive,
        success,
        fail,
        error,
        finish,
        handleError,
        dbblConfig.network.cache.ttlMilliseconds,
    ]);
    return useObjectMemo(__assign(__assign({ send: send }, messaging.state), request.state));
};
export var useRequestWithFeedback = function () {
    var handleError = useErrorHandling();
    return useRequestWithMessaging(handleError);
};
export var useRequestWithoutErrorHandling = function () {
    return useRequestWithMessaging();
};
var wasFulfilled = function (item) { return item.status === "fulfilled"; };
var wasRejected = function (item) { return item.status === "rejected"; };
var newArray = function (size, fill) {
    return new Array(size).fill(null).map(fill instanceof Function ? fill : function () { return fill; });
};
var useArrayState = function (size, initial) {
    var _a = __read(useState(newArray(size, initial)), 2), values = _a[0], setValues = _a[1];
    var actualSize = values.length;
    useEffect(function () {
        if (actualSize !== size)
            setValues(newArray(size, initial));
    }, [actualSize, size, initial]);
    var setValue = useCallback(function (index, value) {
        setValues(values.map(function (old, idx) { return (idx === index ? value : old); }));
    }, [values]);
    return [values, setValue];
};
var EMPTY_STATE = {
    loading: false,
    data: {},
    status: REQUEST_STATUS.idle,
    error: null,
};
var useRequests = function (n) {
    var _a = __read(useArrayState(n, EMPTY_STATE), 2), states = _a[0], setState = _a[1];
    var start = useCallback(function (i) {
        setState(i, __assign(__assign({}, states[i]), { status: REQUEST_STATUS.loading, data: {}, loading: true }));
    }, [states, setState]);
    var receive = useCallback(function (i, d) {
        setState(i, __assign(__assign({}, states[i]), { status: REQUEST_STATUS.success, data: d }));
    }, [states, setState]);
    var fail = useCallback(function (i, e) {
        setState(i, __assign(__assign({}, states[i]), { status: REQUEST_STATUS.error, error: e }));
    }, [states, setState]);
    var finish = useCallback(function (i) {
        setState(i, __assign(__assign({}, states[i]), { loading: false }));
    }, [states, setState]);
    return useMemo(function () {
        return states.map(function (state, i) { return ({
            state: state,
            start: start.bind(null, i),
            receive: receive.bind(null, i),
            fail: fail.bind(null, i),
            finish: finish.bind(null, i),
        }); });
    }, [states, start, receive, fail, finish]);
};
export var useManyRequestsWithFeedback = function (size) {
    var fetchApi = useLibrary("network").fetchApi;
    var requests = useRequests(size);
    var messaging = useMessaging();
    var initialize = messaging.initialize, success = messaging.success, partial = messaging.partial, error = messaging.error;
    var requestFns = requests
        .map(function (_a) {
        var start = _a.start, receive = _a.receive, fail = _a.fail, finish = _a.finish;
        return [start, receive, fail, finish];
    })
        .flat();
    var sendAll = useCallback(function (props) {
        var actions = props.actions, _a = props.onData, onData = _a === void 0 ? noop : _a, _b = props.onSuccess, onSuccess = _b === void 0 ? noop : _b, _c = props.onPartial, onPartial = _c === void 0 ? noop : _c, _d = props.onError, onError = _d === void 0 ? noop : _d, _e = props.onFinally, onFinally = _e === void 0 ? noop : _e;
        initialize(props.messaging);
        var promises = actions.map(function (_a, index) {
            var url = _a.url, _b = _a.options, options = _b === void 0 ? {} : _b;
            return __awaiter(void 0, void 0, void 0, function () {
                var _c, start, receive, fail, finish, raw, data, err_1;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!requests[index])
                                throw new Error("Number of actions (".concat(actions.length, ") exceeds preset limit (").concat(size, ")"));
                            _c = requests[index], start = _c.start, receive = _c.receive, fail = _c.fail, finish = _c.finish;
                            start();
                            _d.label = 1;
                        case 1:
                            _d.trys.push([1, 4, 5, 6]);
                            return [4 /*yield*/, fetchApi(url, options)];
                        case 2:
                            raw = _d.sent();
                            return [4 /*yield*/, raw.json()];
                        case 3:
                            data = (_d.sent());
                            receive(data);
                            return [2 /*return*/, data];
                        case 4:
                            err_1 = _d.sent();
                            fail(err_1);
                            throw err_1;
                        case 5:
                            finish();
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        });
        Promise.allSettled(promises)
            .then(function (results) {
            var allSucceded = results.every(wasFulfilled);
            var allFailed = results.every(wasRejected);
            var data = [];
            var errors = [];
            results.forEach(function (result, index) {
                var action = actions[index];
                if (wasRejected(result)) {
                    errors.push({ error: result.reason, action: action });
                    data.push(null);
                }
                else {
                    data.push(result.value);
                }
            });
            if (allSucceded) {
                onData(data);
                onSuccess();
                success();
            }
            else if (allFailed) {
                onError();
                error();
            }
            else {
                onData(data);
                onPartial(errors);
                partial();
            }
        })
            .finally(onFinally);
    }, __spreadArray([fetchApi, initialize, success, partial, error, size], __read(requestFns), false));
    // ToDo: Memoize this
    return __assign(__assign({ sendAll: sendAll }, messaging.state), { requests: requests.map(function (r) { return r.state; }), loading: requests.some(function (r) { return r.state.loading; }) });
};
