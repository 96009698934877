var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TextInput } from "@narmi/design_system";
/**
 *
 * NDS TextInput configured for entering a routing number.
 * `RoutingNumberTextInput` is intended to be used as a controlled component.
 */
// borrowed from byzantine (since we can't currently import)
export var validateRoutingNumber = function (string) {
    if (!string || Number(string) === 0) {
        // if it's falsey, it cannot be a routing number. Technically, 000000000
        // is a valid routing number, but it's inactive.
        return "Required";
    }
    var genericError = "Please enter a valid 9-digit routing number.";
    var routing = string.toString();
    // must be exactly 9 digits
    var match = routing.match("^\\d{9}$");
    if (!match) {
        return genericError;
    }
    // The first two digits of the nine digit RTN must be in the ranges 00
    // through 12, 21 through 32, 61 through 72, or 80. Ref:
    // https://en.wikipedia.org/wiki/Routing_transit_number
    var firstTwo = parseInt(routing.substring(0, 2), 10);
    var firstTwoValid = (firstTwo >= 0 && firstTwo <= 12) ||
        (firstTwo >= 21 && firstTwo <= 32) ||
        (firstTwo >= 61 && firstTwo <= 72) ||
        firstTwo === 80;
    if (!firstTwoValid) {
        return genericError;
    }
    // Calculate the checksum, ref:
    // http://www.siccolo.com/Articles/SQLScripts/how-to-create-sql-to-calculate-routing-check-digit.html
    var weights = [3, 7, 1];
    var sum = 0;
    for (var i = 0; i < 8; i += 1) {
        sum += parseInt(routing[i], 10) * weights[i % 3];
    }
    if ((10 - (sum % 10)) % 10 === parseInt(routing[8], 10)) {
        return null;
    }
    return genericError;
};
var RoutingNumberTextInput = function (_a) {
    var _b = _a.value, value = _b === void 0 ? "" : _b, onChange = _a.onChange, label = _a.label, _c = _a.name, name = _c === void 0 ? "routing_number" : _c, _d = _a.id, id = _d === void 0 ? "routing_number" : _d, _e = _a.onBlur, onBlur = _e === void 0 ? undefined : _e, _f = _a.error, error = _f === void 0 ? "" : _f, otherTextInputProps = __rest(_a, ["value", "onChange", "label", "name", "id", "onBlur", "error"]);
    return (_jsx(TextInput, __assign({ id: id, name: name, label: label, value: value || "", 
        // By default React.ChangeEvent.nativeEvent does not include inputType
        onChange: function (event) {
            var strippedValue = event.target.value
                .replace(/[^\d]/g, "")
                .substring(0, 9);
            // allows for deletion of inputted value if last displayed char is non-numeric
            // if the stripped target value is the same as the stored parent value, then
            // the backspace action deleted a non-numeric character.
            if (event.nativeEvent.inputType === "deleteContentBackward" &&
                strippedValue === value) {
                onChange && onChange(value.substring(0, value.length - 1));
                return;
            }
            onChange && onChange(strippedValue);
        }, onBlur: onBlur, error: error }, otherTextInputProps)));
};
export default RoutingNumberTextInput;
