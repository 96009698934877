var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useObjectMemo, usePrevious } from "../../../../hooks";
import { dualApprovalRequired, wires, useInstitution, useUser, fedwireTemplates, } from "../../../entities";
import { useFedwireTemplates, useOneFedwireTemplate, } from "../../../entities/fedwireTemplates/hooks";
import filters from "../../../../../filters";
import { methodSelectorForm, sendViaTemplateForm } from "../forms";
import { upsertTemplateForm, transformApiTemplateToForm, } from "../forms/upsertTemplate.form";
import { selectFedwireTemplateDetails, selectFedwireTemplatesWithRecipients, } from "../selectors";
import { TEMPLATE_UPDATED_FLASH_DURATION } from "../consts";
import { useCreateOrEditTemplate } from "../requests";
import { useLibrary } from "../../../../providers";
import { useWirePermissions } from "./permissions";
var useWireReasons = function () {
    var institution = useInstitution();
    return (institution === null || institution === void 0 ? void 0 : institution.wire_reason_types) || [];
};
export var useData = function () {
    var user = useUser();
    var institution = useInstitution();
    var templates = useFedwireTemplates();
    var templatesWithRecipients = useSelector(selectFedwireTemplatesWithRecipients);
    var recipients = useSelector(wires.selectWireRecipients);
    var sortedFedwireTemplateIds = useSelector(fedwireTemplates.selectSortedFedwireTemplateIds);
    var sudoModeRequiredForWires = institution === null || institution === void 0 ? void 0 : institution.sudo_mode_required_for_wires;
    return useObjectMemo({
        user: user,
        institution: institution,
        templates: templates,
        recipients: recipients,
        sudoModeRequiredForWires: sudoModeRequiredForWires,
        wireReasons: (institution === null || institution === void 0 ? void 0 : institution.wire_reason_types) || [],
        templatesWithRecipients: templatesWithRecipients,
        sortedFedwireTemplateIds: sortedFedwireTemplateIds,
    });
};
export var useFedwireTemplatePreview = function (initiallyCollapsed) {
    if (initiallyCollapsed === void 0) { initiallyCollapsed = true; }
    var _a = __read(useState(initiallyCollapsed), 2), collapsed = _a[0], setCollapsed = _a[1];
    var onToggle = useCallback(function () { return setCollapsed(function (c) { return !c; }); }, []);
    return useObjectMemo({
        collapsed: collapsed,
        onToggle: onToggle,
    });
};
export var useSubmitSendViaTemplateForm = function () {
    var _a = useData(), wireReasons = _a.wireReasons, sudoModeRequiredForWires = _a.sudoModeRequiredForWires;
    var form = sendViaTemplateForm.useForm();
    var fedwireTemplateId = form.values.fedwireTemplateId, submitForm = form.submitForm;
    var clearAllToasts = useLibrary("toasts").clearAllToasts;
    var onContinue = useCallback(function (next, trySudo) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, submitForm()];
                case 1:
                    result = _a.sent();
                    if (!result.success) {
                        return [2 /*return*/, null];
                    }
                    clearAllToasts();
                    if (!sudoModeRequiredForWires) {
                        return [2 /*return*/, next()];
                    }
                    return [2 /*return*/, trySudo()];
            }
        });
    }); }, [submitForm, sudoModeRequiredForWires, clearAllToasts]);
    return useObjectMemo({
        wireReasons: wireReasons,
        fedwireTemplateId: fedwireTemplateId,
        onContinue: onContinue,
        form: form,
    });
};
export var useTemplateSelector = function () {
    var sortedFedwireTemplateIds = useData().sortedFedwireTemplateIds;
    var showManageTemplatesUI = useWirePermissions().showManageTemplatesUI;
    var method = methodSelectorForm.useForm().values.method;
    var form = sendViaTemplateForm.useForm();
    var fedwireTemplateId = form.values.fedwireTemplateId, setFieldValue = form.setFieldValue, touched = form.touched, errors = form.errors, resetForm = form.resetForm;
    var fedwireTemplate = useOneFedwireTemplate(fedwireTemplateId);
    useEffect(function () {
        setFieldValue("fedwireTemplate", fedwireTemplate, false);
    }, [setFieldValue, fedwireTemplate]);
    var onSelectTemplate = useCallback(function (templateId) {
        resetForm();
        var value = templateId === fedwireTemplateId ? "" : templateId;
        setFieldValue("fedwireTemplateId", value, true);
    }, [fedwireTemplateId, setFieldValue, resetForm]);
    return useObjectMemo({
        showManageTemplatesUI: showManageTemplatesUI,
        sortedFedwireTemplateIds: sortedFedwireTemplateIds,
        selectedTemplateId: fedwireTemplateId,
        onSelectTemplate: onSelectTemplate,
        method: method,
        error: touched.fedwireTemplateId && errors.fedwireTemplateId,
        form: form,
        resetForm: resetForm,
    });
};
export var useTemplateDetails = function (fedwireTemplateId) {
    var data = useSelector(function (state) {
        return selectFedwireTemplateDetails(state, fedwireTemplateId);
    });
    return useMemo(function () {
        if (!data) {
            return null;
        }
        var template = data.template, recipient = data.recipient, account = data.account;
        var templateDetails = {
            header: "Template details",
            rows: [
                template.amount ? filters.currency(template.amount) : null,
                template.memo,
                template.wire_reason,
                account.getShortDescription(false),
            ],
        };
        var recipientDetails = {
            header: "Recipient details",
            rows: [recipient.name].concat(recipient.address
                .toString()
                .split(",")
                .map(function (row) { return row.trim(); })),
        };
        var bankDetails = {
            header: "Bank details",
            rows: [recipient.bankDetails],
        };
        return {
            title: template.name,
            subheader: recipient.name,
            templateDetails: templateDetails,
            recipientDetails: recipientDetails,
            bankDetails: bankDetails,
            versionId: template.version_id,
        };
    }, [data]);
};
export var useTemplateUpdated = function (versionId) {
    var _a = __read(useState(false), 2), showUpdated = _a[0], setShowUpdated = _a[1];
    var timeout = useRef();
    var clearLocalTimeout = useCallback(function () {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
    }, []);
    useEffect(function () {
        return clearLocalTimeout;
    }, [clearLocalTimeout]);
    var flashUpdated = useCallback(function () {
        clearLocalTimeout();
        setShowUpdated(true);
        timeout.current = setTimeout(function () {
            setShowUpdated(false);
        }, TEMPLATE_UPDATED_FLASH_DURATION);
    }, [clearLocalTimeout]);
    var previousVersionId = usePrevious(versionId);
    useEffect(function () {
        if (!!previousVersionId && !!versionId && previousVersionId !== versionId) {
            flashUpdated();
        }
    }, [clearLocalTimeout, flashUpdated, previousVersionId, versionId]);
    return showUpdated;
};
export var useReviewDetails = function () {
    var _a = sendViaTemplateForm.useForm().values, fedwireTemplateId = _a.fedwireTemplateId, amount = _a.amount, memo = _a.memo, wireReason = _a.wireReason;
    var data = useSelector(function (state) { return selectFedwireTemplateDetails(state, fedwireTemplateId); }, 
    // ToDo is the isEqual needed?
    _.isEqual);
    var institution = useInstitution();
    var requiresDualApproval = useSelector(dualApprovalRequired);
    var termsUrl = institution === null || institution === void 0 ? void 0 : institution.ach_terms_url;
    var wireFee = institution === null || institution === void 0 ? void 0 : institution.wire_display_fee;
    return useMemo(function () {
        if (!data) {
            return null;
        }
        var template = data.template, recipient = data.recipient, account = data.account;
        var templateSection = {
            header: template.name,
            rows: [
                { header: "Recipient name", value: recipient.name },
                { header: "Address", value: recipient.address.toString() },
                {
                    header: "Bank details",
                    value: recipient.bankDetails,
                },
                {
                    header: "Funding account",
                    value: account.getShortDescription(false),
                },
            ],
        };
        var additionalDetailsSection = {
            header: "Additional details",
            rows: [],
        };
        var amountRow = function (value) { return ({
            header: "Amount",
            value: filters.currency(value),
        }); };
        if (template.amount) {
            templateSection.rows.push(amountRow(template.amount));
        }
        else if (amount) {
            additionalDetailsSection.rows.push(amountRow(amount));
        }
        var wireReasonRow = function (value) { return ({
            header: "Wire reason",
            value: value,
        }); };
        if (template.wire_reason) {
            templateSection.rows.push(wireReasonRow(template.wire_reason));
        }
        else if (wireReason) {
            additionalDetailsSection.rows.push(wireReasonRow(wireReason));
        }
        var memoRow = function (value) { return ({
            header: "Memo",
            value: value,
        }); };
        if (template.memo) {
            templateSection.rows.push(memoRow(template.memo));
        }
        else if (memo) {
            additionalDetailsSection.rows.push(memoRow(memo));
        }
        if (wireFee) {
            additionalDetailsSection.rows.push({
                header: "Wire fee",
                value: wireFee,
            });
        }
        var sections = [templateSection];
        if (additionalDetailsSection.rows.length) {
            sections.push(additionalDetailsSection);
        }
        var disclosure = termsUrl &&
            "By ".concat(requiresDualApproval ? "submitting," : "tapping \"Send wire\"", " you acknowledge that you agree to the terms of the [electronic funds transfer agreement.](").concat(termsUrl, ")");
        var buttonLabel = requiresDualApproval ? "Submit" : "Send wire";
        return {
            sections: sections,
            disclosure: disclosure,
            buttonLabel: buttonLabel,
        };
    }, [amount, data, memo, requiresDualApproval, termsUrl, wireFee, wireReason]);
};
export var useConditionalDetails = function () {
    var form = sendViaTemplateForm.useForm();
    var fedwireTemplateId = form.values.fedwireTemplateId;
    var fedwireTemplate = useOneFedwireTemplate(fedwireTemplateId);
    var requiresAmount = !(fedwireTemplate === null || fedwireTemplate === void 0 ? void 0 : fedwireTemplate.amount);
    var requiresMemo = !(fedwireTemplate === null || fedwireTemplate === void 0 ? void 0 : fedwireTemplate.memo);
    var requiresWireReason = !(fedwireTemplate === null || fedwireTemplate === void 0 ? void 0 : fedwireTemplate.wire_reason);
    return useObjectMemo({
        templateSelected: !!fedwireTemplateId,
        additionalDetailsRequired: requiresAmount || requiresMemo || requiresWireReason,
        requiresAmount: requiresAmount,
        requiresMemo: requiresMemo,
        requiresWireReason: requiresWireReason,
    });
};
export var useMethodSelector = function () {
    var form = methodSelectorForm.useForm();
    var method = form.values.method, submitForm = form.submitForm, resetForm = form.resetForm;
    var submitMethod = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, submitForm()];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, result.success];
            }
        });
    }); }, [submitForm]);
    return useObjectMemo({
        submitMethod: submitMethod,
        resetForm: resetForm,
        method: method,
        form: form,
    });
};
export var useAddOrCreateTemplateForm = function (existingTemplateId) {
    var form = upsertTemplateForm.useForm();
    var setValues = form.setValues, setFieldValue = form.setFieldValue, submitForm = form.submitForm;
    var existingTemplate = useOneFedwireTemplate(existingTemplateId);
    useEffect(function () {
        if (existingTemplate) {
            setValues(transformApiTemplateToForm(existingTemplate), false);
        }
    }, [existingTemplate, setValues]);
    var onRemoveRecipient = useCallback(function () {
        setFieldValue("wireRecipientId", "", false);
    }, [setFieldValue]);
    var _a = useCreateOrEditTemplate(), createWireTemplate = _a.createWireTemplate, loading = _a.loading;
    var onSubmit = useCallback(function (onSuccess) { return __awaiter(void 0, void 0, void 0, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, submitForm()];
                case 1:
                    result = _a.sent();
                    if (!result.success) {
                        return [2 /*return*/, null];
                    }
                    return [2 /*return*/, createWireTemplate(existingTemplateId, { onSuccess: onSuccess })];
            }
        });
    }); }, [createWireTemplate, existingTemplateId, submitForm]);
    var wireReasons = useWireReasons();
    return useObjectMemo({
        onRemoveRecipient: onRemoveRecipient,
        wireReasons: wireReasons,
        onSubmit: onSubmit,
        loading: loading,
        form: form,
    });
};
