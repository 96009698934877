var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Component, createContext, useContext, useEffect, useState, useCallback, useMemo, } from "react";
import PropTypes from "prop-types";
import Portal from "../Portal";
import SuccessMessage from "./SuccessMessage";
import NegativeMessage from "./NegativeMessage";
import InfoMessage from "./InfoMessage";
var GENERIC_ERROR_MESSAGE = "We are having trouble processing your request. Please contact Support.";
var isNotificationType = function (notificationType) {
    return ["success", "negative", "info", "dismissable_success"].includes(notificationType);
};
var Notification = function (_a) {
    var _b = _a.isPortaled, isPortaled = _b === void 0 ? true : _b;
    var _c = useNotificationContext(), notificationState = _c.notificationState, sendNotification = _c.sendNotification, deleteNotification = _c.deleteNotification;
    useEffect(function () {
        // transforms query strings into toasts on initial load
        var params = new URLSearchParams(window.location.search);
        params.forEach(function (val, key) {
            if (isNotificationType(key)) {
                sendNotification({ type: key, text: val });
            }
        });
        var urlSearchParams = new URLSearchParams(window.location.search);
        ["success", "negative", "info", "dismissable_success"].forEach(function (type) {
            return urlSearchParams.delete(type);
        });
        var queryString = urlSearchParams.toString();
        window.history.replaceState({}, "", "".concat(document.location.href.split("?")[0], "?").concat(queryString));
    }, []);
    var renderNotification = function () {
        var notifications = notificationState.map(function (notification) {
            var type = notification.type, text = notification.text, creationTime = notification.creationTime;
            if (!creationTime) {
                return;
            }
            var getMessageComponentType = function () {
                switch (type) {
                    case "success": {
                        return SuccessMessage;
                    }
                    case "negative": {
                        return NegativeMessage;
                    }
                    case "info": {
                        return InfoMessage;
                    }
                    case "dismissable_success": {
                        return SuccessMessage;
                    }
                    default: {
                        return "div";
                    }
                }
            };
            var MessageComponentType = getMessageComponentType();
            return (_jsx(MessageComponentType, { dismissMessage: function () {
                    deleteNotification(notification);
                }, text: text, creationTime: creationTime, notificationType: type }, JSON.stringify(notification)));
        });
        return _jsx(_Fragment, { children: notifications });
    };
    var notifications = Boolean(notificationState.length)
        ? renderNotification()
        : null;
    return isPortaled ? (_jsx(Portal, { rootId: "notifications-container", children: notifications })) : (notifications);
};
Notification.propTypes = {};
export var NotificationContext = createContext({
    notificationState: [],
    sendNotification: function () { },
    deleteNotification: function () { },
    clearNotifications: function () { },
    hasParentNotificationContext: false,
});
export var useNotificationContext = function () { return useContext(NotificationContext); };
export var NotificationContextProvider = function (_a) {
    var children = _a.children, _b = _a.isPortaled, isPortaled = _b === void 0 ? true : _b;
    var _c = useNotificationContext(), sendNotificationToParent = _c.sendNotification, deleteNotificationForParent = _c.deleteNotification, clearParentNotifications = _c.clearNotifications;
    var _d = __read(useState([]), 2), notificationState = _d[0], setNotificationState = _d[1];
    var sendNotification = useCallback(function (notification) {
        // add toast to notification state (no duplicate messages allowed)
        setNotificationState(function (currentState) {
            // if the new notification's text is an array, convert to string.
            var cleanedText = notification.text;
            if (Array.isArray(notification.text)) {
                cleanedText = notification.text.join(". ");
            }
            if (cleanedText === "Network Error") {
                cleanedText = GENERIC_ERROR_MESSAGE;
            }
            if (!currentState.every(function (n) { return n.type !== notification.type || n.text !== cleanedText; })) {
                return currentState;
            }
            return __spreadArray(__spreadArray([], __read(currentState), false), [
                {
                    type: notification.type,
                    text: cleanedText,
                    creationTime: Date.now(),
                },
            ], false);
        });
    }, []);
    var deleteNotification = useCallback(function (notification) {
        // removes toast with the same type and text
        setNotificationState(function (currentState) {
            return currentState.filter(function (n) { return n.type !== notification.type || n.text !== notification.text; });
        });
    }, []);
    var clearNotifications = useCallback(function () {
        setNotificationState([]);
    }, []);
    var notificationValue = useMemo(function () { return ({
        notificationState: notificationState,
        sendNotification: sendNotification,
        deleteNotification: deleteNotification,
        clearNotifications: clearNotifications,
        sendNotificationToParent: sendNotificationToParent,
        deleteNotificationForParent: deleteNotificationForParent,
        clearParentNotifications: clearParentNotifications,
        hasParentNotificationContext: true,
    }); }, [
        notificationState,
        sendNotification,
        deleteNotification,
        clearNotifications,
        sendNotificationToParent,
        deleteNotificationForParent,
        clearParentNotifications,
    ]);
    return (_jsxs(NotificationContext.Provider, { value: notificationValue, children: [_jsx(Notification, { isPortaled: isPortaled }), children] }));
};
NotificationContextProvider.propTypes = {
    children: PropTypes.node,
};
export function withNotifications(WrappedComponent) {
    var WithNotifications = /** @class */ (function (_super) {
        __extends(WithNotifications, _super);
        function WithNotifications() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        WithNotifications.prototype.render = function () {
            return WrappedComponent ? (_jsx(WrappedComponent, __assign({ notificationState: this.context.notificationState, sendNotification: this.context.sendNotification }, this.props))) : (_jsx(_Fragment, {}));
        };
        WithNotifications.contextType = NotificationContext;
        WithNotifications.displayName = "WithNotifications(".concat(WrappedComponent ? WrappedComponent.displayName : null, ")");
        return WithNotifications;
    }(Component));
    return WithNotifications;
}
export default NotificationContext;
