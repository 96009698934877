var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState, useRef, useEffect } from "react";
import { TextInput } from "@narmi/design_system";
export function phoneFormatter(value) {
    if (value === null || value === undefined || value.length === 0) {
        return "";
    }
    var numerical = value.replace(/[^0-9]/g, "");
    var length = numerical.length;
    // formatting area code
    if (length < 4) {
        if (numerical[0] === "1" && length === 1) {
            return "";
        }
        return "(".concat(numerical.substring(0, length), ")");
    }
    // formatting the next three digits along with area code
    if (length < 7) {
        // Checks to see if country code is included
        if (numerical[0] === "1") {
            return "(".concat(numerical.substring(1, 4), ") ").concat(numerical.substring(4, length));
        }
        return "(".concat(numerical.substring(0, 3), ") ").concat(numerical.substring(3, length));
    }
    // formatting the full phone number
    if (length < 12) {
        // Checks to see if country code is included
        if (numerical[0] === "1") {
            return "(".concat(numerical.substring(1, 4), ") ").concat(numerical.substring(4, 7), "-").concat(numerical.substring(7, length));
        }
    }
    // Checks to see if country code is included
    if (numerical[0] === "1") {
        return "(".concat(numerical.substring(1, 4), ") ").concat(numerical.substring(4, 7), "-").concat(numerical.substring(7, 11));
    }
    return "(".concat(numerical.substring(0, 3), ") ").concat(numerical.substring(3, 6), "-").concat(numerical.substring(6, 10));
}
function phoneUnformatter(value) {
    // strips all punctuation
    if (value === null || value === undefined || value.length === 0) {
        return "";
    }
    var numerical = value.replace(/[^0-9]/g, "");
    if (numerical.length === 10) {
        // if there are 10 digits and the first is not a 1
        // add country code
        return "+1".concat(numerical);
    }
    if (numerical.length > 10) {
        return "+".concat(numerical);
    }
    return numerical;
}
var PhoneTextInput = function (props) {
    /*
    in order for this to work with azul.Form or azul.ContextForm,
    props.field cannot be passed into TextInput.
    otherwise the parent form will directly manage TextInput's value
    */
    var onChange = props.onChange, _a = props.label, label = _a === void 0 ? "Phone number" : _a, value = props.value, field = props.field, nativeElementProps = __rest(props, ["onChange", "label", "value", "field"]);
    var _b = __read(useState(null), 2), cursorPosition = _b[0], setCursorPosition = _b[1];
    var _c = __read(useState(false), 2), cursorNeedsUpdate = _c[0], setCursorNeedsUpdate = _c[1];
    var inputRef = useRef();
    var displayValue = phoneFormatter(phoneUnformatter(value));
    var handleInput = function (event) {
        if (!inputRef.current) {
            return;
        }
        var currentCursorPosition = inputRef.current.selectionStart;
        var parsedValue = event.target.value
            .replace(/[^\d]/g, "")
            .substring(0, 11);
        var isCursorInMiddleOfString = (currentCursorPosition !== null && currentCursorPosition !== void 0 ? currentCursorPosition : 0) < displayValue.length;
        if (event.nativeEvent.inputType === "deleteContentBackward" &&
            parsedValue === value) {
            var valueLastCharRemoved = value.substring(0, value.length - 1);
            onChange(valueLastCharRemoved);
        }
        else {
            onChange(phoneUnformatter(phoneFormatter(parsedValue)));
        }
        if (isCursorInMiddleOfString) {
            setCursorPosition(currentCursorPosition);
            setCursorNeedsUpdate(true);
        }
    };
    useEffect(function () {
        if (cursorNeedsUpdate && inputRef.current) {
            inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
            setCursorNeedsUpdate(false);
        }
    }, [cursorNeedsUpdate]);
    return (_jsx("div", { children: _jsx(TextInput, __assign({ id: "phone", label: label, onChange: handleInput, value: displayValue, inputMode: "numeric" }, nativeElementProps, { ref: inputRef })) }));
};
export default PhoneTextInput;
