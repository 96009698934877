var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useLocalization } from "@fluent/react";
import { ContentCard, phoneFormatter } from "cerulean";
import { useCurrentUser } from "../../../../contexts/CurrentUserContext";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";
import ProfileSettingRow from "../../ProfileSettingRow";
import AddressSettings from "../Address/AddressSettings";
import UsernameEditDialog from "./UsernameEditDialog";
import EmailEditDialog from "./EmailEditDialog";
import PhoneNumberEditDialog from "./PhoneNumberEditDialog";
var ContactInfoCard = function () {
    var currentUser = useCurrentUser().currentUser;
    var l10n = useLocalization().l10n;
    var userFeatures = useUserFeatures();
    var isPersonalBankingUser = !(currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness());
    var isBusinessUser = currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusiness();
    var _a = __read(useState(false), 2), isUsernameDialogOpen = _a[0], setIsUsernameDialogOpen = _a[1];
    var _b = __read(useState(false), 2), isEmailEditDialogOpen = _b[0], setIsEmailEditDialogOpen = _b[1];
    var _c = __read(useState(false), 2), isPhoneNumberEditDialogOpen = _c[0], setIsPhoneNumberEditDialogOpen = _c[1];
    var getEmailTooltipText = function () {
        var emailTooltipText = null;
        if (currentUser === null || currentUser === void 0 ? void 0 : currentUser.isBusinessAccountHolder()) {
            emailTooltipText = l10n.getString("tooltip-business-email-setting-account-holder");
        }
        else if (isBusinessUser) {
            emailTooltipText = l10n.getString("tooltip-business-email-setting-subuser");
        }
        return emailTooltipText;
    };
    return (_jsxs(ContentCard, { children: [_jsx("h4", { className: "contact-info-heading nds-sans fontSize--heading5", children: "Contact info" }), _jsx(ProfileSettingRow, { label: "Username", value: currentUser === null || currentUser === void 0 ? void 0 : currentUser.username, editOnClick: function () { return setIsUsernameDialogOpen(true); }, isReadOnly: !(userFeatures === null || userFeatures === void 0 ? void 0 : userFeatures.can_change_username) }), _jsx(UsernameEditDialog, { isOpen: isUsernameDialogOpen, closeDialog: function () { return setIsUsernameDialogOpen(false); } }), _jsx(ProfileSettingRow, { label: "Email", value: currentUser === null || currentUser === void 0 ? void 0 : currentUser.email, editOnClick: function () { return setIsEmailEditDialogOpen(true); }, testId: "email-setting", tooltipText: getEmailTooltipText() }), _jsx(EmailEditDialog, { isOpen: isEmailEditDialogOpen, closeDialog: function () { return setIsEmailEditDialogOpen(false); } }), _jsx(ProfileSettingRow, { label: "Phone", value: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.phone) &&
                    phoneFormatter(currentUser === null || currentUser === void 0 ? void 0 : currentUser.phone.number), editOnClick: function () { return setIsPhoneNumberEditDialogOpen(true); }, isReadOnly: !(userFeatures === null || userFeatures === void 0 ? void 0 : userFeatures.phone_change), testId: "phone-setting", tooltipText: isBusinessUser
                    ? l10n.getString("tooltip-business-phone-number-setting")
                    : null }), _jsx(PhoneNumberEditDialog, { closeDialog: function () { return setIsPhoneNumberEditDialogOpen(false); }, isOpen: isPhoneNumberEditDialogOpen }), isPersonalBankingUser && _jsx(AddressSettings, {})] }));
};
export default ContactInfoCard;
