var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Filters from "./filters";
var Balances = /** @class */ (function () {
    function Balances(props) {
        Object.assign(this, props);
    }
    Balances.prototype.updateAccount = function (account) {
        account.updateBalances(this);
    };
    Balances.prototype.displayAvailableBalance = function () {
        return {
            title: "Available balance",
            value: this.available,
        };
    };
    Balances.prototype.displayCurrentBalance = function () {
        return {
            title: "Current balance",
            value: this.ledger,
        };
    };
    Balances.prototype.displayBreakdown = function () {
        return [
            __assign(__assign({}, this.displayAvailableBalance()), { description: "Your immediately available funds to use, including any pending transactions or temporary holds on your account." }),
            __assign(__assign({}, this.displayCurrentBalance()), { description: "The total amount of funds in your account, not including temporary holds or pending transactions." }),
        ];
    };
    Balances.deserialize = function (payload) {
        return new Balances(__assign(__assign({}, payload), { primary: Filters.currency(payload === null || payload === void 0 ? void 0 : payload.primary), ledger: Filters.currency(payload === null || payload === void 0 ? void 0 : payload.ledger), available: Filters.currency(payload === null || payload === void 0 ? void 0 : payload.available), raw: payload }));
    };
    return Balances;
}());
export default Balances;
