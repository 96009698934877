import ApiHttp from "./ApiHttp";
import utils from "./utils";

class Wire {
  constructor(props) {
    this.from_account = props.from_account;
    this.amount = props.amount;
    this.beneficiary_name = props.beneficiary_name;
    this.beneficiary_address_1 = props.beneficiary_address_1;
    this.beneficiary_address_2 = props.beneficiary_address_2;
    this.beneficiary_address_3 = props.beneficiary_address_3;
    this.city = props.city;
    this.region_code = props.region_code;
    this.postal_code = props.postal_code;
    this.country_code = props.country_code || "US";
    this.to_account_routing_number = props.to_account_routing_number;
    this.to_account_number = props.to_account_number;
    this.memo_1 = props.memo_1;
    this.memo_2 = props.memo_2;
    this.memo_3 = props.memo_3;
    this.memo_4 = props.memo_4;
    this.state = props.state;
    this.display_state = props.display_state;
    this.created_at = props.created_at;
    this.processed_at = props.processed_at;
    this.id = props.id;
    this.template_name = props.template_name;
    this.itempotencyKey = props.itempotencyKey;
  }

  static getPendingWires(account_uuid) {
    return ApiHttp.fetch("wires", { method: "GET" }, { account_uuid })
      .then((response) => {
        if (!response?.wires || !Array.isArray(response?.wires))
          throw new Error("Could not fetch wires");
        return response.wires.map((wireResponse) =>
          Wire.deserialize(wireResponse)
        );
      })
      .catch((error) => {
        // Not an error when the client lacks permissions to fetch wires
        if (
          [
            "Not found.",
            "You do not have permission to perform this action.",
            "User role does not have authorization for this action."
          ].includes(error)
        ) {
          return [];
        }
        throw error;
      });
  }

  static ALL_PENDING_STATUSES = {
    Submitted: "End user submitted a wire",
    Rejected: "Rejected by staff",
    Canceled: "Rejected by an additional holder",
  };

  static BUSINESS_ONLY_PENDING_STATUSES = {
    "Pending dual approval":
      "Additional account holder needs to approve the wire",
    "Rejected approval": "Dual approval rejected the wire",
  };

  static getConsumerPendingStatuses() {
    return Object.entries(this.ALL_PENDING_STATUSES);
  }

  /* slight re-ordering of pending statuses necessitates this function */
  static getBusinessPendingStatuses() {
    return Object.entries(
      Object.assign(
        { Submitted: null },
        this.BUSINESS_ONLY_PENDING_STATUSES,
        this.ALL_PENDING_STATUSES
      )
    );
  }

  // we should deprecate/update this method once we've removed the old wires azul/ code
  newWirePayload() {
    return {
      from_account: this.from_account,
      amount: utils.dollarsToPennies(this.amount),
      beneficiary_name: this.beneficiary_name,
      beneficiary_address_1: this.beneficiary_address_1,
      beneficiary_address_2: this.beneficiary_address_2,
      beneficiary_address_3:
        this.beneficiary_address_3 ||
        `${this.city}, ${this.region_code} ${this.postal_code} ${this.country_code}`.substring(
          0,
          35
        ),
      to_account_routing_number: this.to_account_routing_number,
      to_account_number: this.to_account_number,
      to_account_institution_name: this.to_account_institution_name, // currently undefined
      memo_1: this.memo_1,
      memo_2: this.memo_2,
      memo_3: this.memo_3,
      memo_4: this.memo_4,
    };
  }

  to(wireRecipient) {
    this.beneficiary_name = wireRecipient.name;
    this.beneficiary_address_1 = wireRecipient.address.street_address;
    this.beneficiary_address_2 = wireRecipient.address.street_address_2;
    this.beneficiary_address_3 =
      `${wireRecipient.address.city}, ${wireRecipient.address.region_code} ${wireRecipient.address.postal_code} ${wireRecipient.address.country_code}`.substring(
        0,
        35
      );
    this.to_account_routing_number = wireRecipient.routing_number;
    this.to_account_number = wireRecipient.account_number;
    return this;
  }

  static splitMemo(memo) {
    return {
      memo_1: memo.slice(0, 35),
      memo_2: memo.slice(35, 70),
      memo_3: memo.slice(70, 105),
      memo_4: memo.slice(105, 140),
    }
  }

  submit() {
    const payload = this.newWirePayload();
    const headers = this.itempotencyKey ? {"Idempotency-Key":this.itempotencyKey} : {};
    return ApiHttp.fetch("wires", { method: "POST", headers }, payload);
  }

  /**
   * Factory method returning a new instance of Wire from
   * an indigo.serialized Wire
   * */
  static deserialize(payload) {
    return new Wire({
      ...payload,
      amount: payload.amount / 100,
    });
  }
}

export default Wire;
