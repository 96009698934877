import React from "react";
import PropTypes from "prop-types";

import { CheckSquare, Square } from "react-feather"; // eslint-disable-line
import Filters from "byzantine/src/filters";
import { Table } from "semantic-ui-react";

function DualApprovalRequestCheckbox({ isSelected, onToggled }) {
  const Checkbox = isSelected ? CheckSquare : Square;
  return (
    <div style={{ width: "16px" }}>
      <Checkbox
        onClick={onToggled}
        size="16px"
        className={isSelected ? "checkbox checked" : "checkbox"}
      />
    </div>
  );
}
DualApprovalRequestCheckbox.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onToggled: PropTypes.func.isRequired,
};

export default function DualApprovalRequestTable({
  dualApprovalRequests,
  selected,
  select,
  unselect,
}) {
  const isEverythingSelected = () =>
    dualApprovalRequests.every((dar) => selected[dar.uuid]);
  const selectEverything = () =>
    dualApprovalRequests.forEach((dar) => select(dar.uuid));
  const unselectEverything = () =>
    dualApprovalRequests.forEach((dar) => unselect(dar.uuid));

  const toggle = (uuid) => (selected[uuid] ? unselect(uuid) : select(uuid));
  const toggleHeader = () =>
    isEverythingSelected() ? unselectEverything() : selectEverything();

  if (!dualApprovalRequests.length) {
    return <div className="emptyState">No pending approvals</div>;
  }

  return (
    <div className={selected ? "selectable-rows" : ""}>
      <Table className="dual-approval-request-table">
        <Table.Header className="mobile-hidden">
          <Table.Row>
            {selected ? (
              <Table.HeaderCell collapsing>
                <DualApprovalRequestCheckbox
                  isSelected={isEverythingSelected()}
                  onToggled={() => toggleHeader()}
                />
              </Table.HeaderCell>
            ) : null}
            <Table.HeaderCell>DATE</Table.HeaderCell>
            <Table.HeaderCell>FROM</Table.HeaderCell>
            <Table.HeaderCell>TO</Table.HeaderCell>
            <Table.HeaderCell>TYPE</Table.HeaderCell>
            <Table.HeaderCell>AMOUNT</Table.HeaderCell>
            <Table.HeaderCell>SUBMITTED BY</Table.HeaderCell>
            <Table.HeaderCell>STATUS</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {dualApprovalRequests.map((dar) => (
            <Table.Row key={dar.uuid}>
              {selected ? (
                <Table.Cell>
                  <DualApprovalRequestCheckbox
                    isSelected={!!selected[dar.uuid]}
                    onToggled={() => toggle(dar.uuid)}
                  />
                </Table.Cell>
              ) : null}
              <Table.Cell>
                <span className="label mobile-only">Date: </span>
                {Filters.americanDate(dar.created_at)}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">From: </span>
                {dar.action.from}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">To: </span>
                {dar.action.to}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">Type: </span>
                {dar.action.type}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">Amount: </span>
                {Filters.currency(Math.floor(dar.action.amount * 100))}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">Submitted By: </span>
                {dar.requester.getDescription()}
              </Table.Cell>
              <Table.Cell>
                <span className="label mobile-only">Status: </span>
                {Filters.humanize(dar.action.status)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
DualApprovalRequestTable.propTypes = {
  dualApprovalRequests: PropTypes.array.isRequired,
  selected: PropTypes.object,
  select: PropTypes.func,
  unselect: PropTypes.func,
};
