import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Feature from "byzantine/src/Feature";
import Account from "byzantine/src/Account";
import { useTranslation } from "react-i18next";
import { useLocalization } from "@fluent/react";
import {
  Button,
  ContextForm,
  Dialog,
  TextInput,
  RadioButtons,
  useFormData,
  NotificationContext,
} from "cerulean";
import UserFeaturesContext from "../contexts/UserFeaturesContext";
import InstitutionSettingsContext from "../contexts/InstitutionSettingsContext";
import SectionCard from "../SectionCard";
import AmountTextInput from "../form/AmountTextInput";
import AddressTitle from "./AddressTitle";
import BillPaySso from "../transfer/BillPaySso";

const StopSingleCheckFields = () => (
  <>
    <ContextForm.Field required>
      <TextInput field="check_number" label="Check number" />
    </ContextForm.Field>
    <ContextForm.Field>
      <AmountTextInput field="amount" label="Amount" />
    </ContextForm.Field>
  </>
);

const StopCheckRangeFields = () => (
  <>
    <ContextForm.Field required>
      <TextInput field="min_check_number" label="First check number in range" />
    </ContextForm.Field>
    <ContextForm.Field required>
      <TextInput field="max_check_number" label="Last check number in range" />
    </ContextForm.Field>
  </>
);

export const StopCheckPaymentForm = ({
  account,
  closeDialog,
  refreshStopPayments,
  stopPaymentInfo,
}) => {
  const { sendNotificationToParent } = useContext(NotificationContext);
  const { formData, onChange, setFormData } = useFormData({});
  const { stop_payment_by_range } = useContext(UserFeaturesContext);

  useEffect(() => {
    if (Object.keys(formData).length > 1)
      setFormData({ request_type: formData?.request_type });
  }, [formData?.request_type]);

  const onSubmit = (callback) => {
    const payload =
      formData.request_type === "range"
        ? {
            min_check_number: formData.min_check_number,
            max_check_number: formData.max_check_number,
          }
        : {
            min_check_number: formData.check_number,
            amount: formData?.amount === "" ? undefined : formData?.amount,
          };
    account
      .stopCheckPayment(payload)
      .then(() => {
        closeDialog();
        callback();
        sendNotificationToParent({
          type: "success",
          text: "Stop payment requested.",
        });
        refreshStopPayments();
      })
      .catch(callback);
  };

  const StopPaymentInfo = () => (
    <>
      <div className="font-size-s-medium-grey">
        {`We can't stop payment on a check that's in process or that has already been deposited or cashed.`}
      </div>
      {stopPaymentInfo && (
        <div className="font-size-s-medium-grey padding--top--l">
          {stopPaymentInfo}
        </div>
      )}
    </>
  );

  return (
    <ContextForm data={formData} onChange={onChange}>
      {stop_payment_by_range && (
        <>
          <div className="margin--bottom--s fontWeight--semibold fontColor--heading">
            What would you like to stop payment on?
          </div>
          <ContextForm.Field
            required
            style={{ paddingBottom: "var(--space-xxs)" }}
          >
            <RadioButtons
              field="request_type"
              name="request_type"
              options={{
                "Single check": "single",
                "Range of checks": "range",
              }}
            />
          </ContextForm.Field>
        </>
      )}
      {(!stop_payment_by_range || formData?.request_type === "single") && (
        <StopSingleCheckFields />
      )}
      {formData?.request_type === "range" && <StopCheckRangeFields />}
      {(!stop_payment_by_range || formData?.request_type) && (
        <StopPaymentInfo />
      )}
      <ContextForm.ActionBar>
        <ContextForm.Action
          noValidation
          onSubmit={closeDialog}
          dangerouslyDisableShowLoading
        >
          <div style={{ margin: "auto var(--space-m) auto auto" }}>
            <Button kind="negative" label="Cancel" />
          </div>
        </ContextForm.Action>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button kind="primary" label="Request stop payment" />
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};
StopCheckPaymentForm.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  closeDialog: PropTypes.func.isRequired,
  refreshStopPayments: PropTypes.func.isRequired,
  stopPaymentInfo: PropTypes.string.isRequired,
};

const StopCheckPayment = ({
  account,
  refreshStopPayments,
  stopPaymentInfo,
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  return (
    <>
      <Button
        kind="plain"
        label="Stop payment on a check"
        onClick={openDialog}
      />
      <Dialog
        isOpen={isDialogOpen}
        onUserDismiss={closeDialog}
        title="Stop payment on a check"
      >
        <div className="margin--top--s">
          <StopCheckPaymentForm
            account={account}
            refreshStopPayments={refreshStopPayments}
            stopPaymentInfo={stopPaymentInfo}
            closeDialog={closeDialog}
          />
        </div>
      </Dialog>
    </>
  );
};
StopCheckPayment.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  refreshStopPayments: PropTypes.func.isRequired,
  stopPaymentInfo: PropTypes.string.isRequired,
};

const CheckWithdrawalForm = ({ account, closeDialog, address }) => {
  const { sendNotificationToParent } = useContext(NotificationContext);
  const { formData, onChange } = useFormData({});
  const onSubmit = (callback) => {
    account
      .withdrawCashiersCheck({ ...formData })
      .then(() => {
        closeDialog();
        callback();
        sendNotificationToParent({
          type: "success",
          text: "Request submitted.",
        });
      })
      .catch((error) => callback(error));
  };

  return (
    <ContextForm data={formData} onChange={onChange}>
      <ContextForm.Field required>
        <AmountTextInput field="amount" label="Amount" />
      </ContextForm.Field>
      <div className="font-size-s-medium-grey">
        {`This cashiers check will be mailed to the address on file:`}
      </div>
      <div className="font-size-s-medium-grey padding--y--xs">
        <AddressTitle noMailing={false} address={address} aligned="left" />
      </div>
      <ContextForm.ActionBar>
        <ContextForm.Action
          noValidation
          onSubmit={closeDialog}
          dangerouslyDisableShowLoading
        >
          <div style={{ margin: "auto var(--space-m) auto auto" }}>
            <Button kind="negative" label="Cancel" />
          </div>
        </ContextForm.Action>
        <ContextForm.Action onSubmit={onSubmit}>
          <Button kind="primary" label="Request check" />
        </ContextForm.Action>
      </ContextForm.ActionBar>
    </ContextForm>
  );
};
CheckWithdrawalForm.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  closeDialog: PropTypes.func.isRequired,
  address: PropTypes.object.isRequired,
};

const CheckWithdrawal = ({ account, address }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const closeDialog = () => setIsDialogOpen(false);
  const openDialog = () => setIsDialogOpen(true);

  return (
    <>
      <Button
        kind="plain"
        label="Request a cashier's check"
        onClick={openDialog}
      />
      <Dialog
        isOpen={isDialogOpen}
        onUserDismiss={closeDialog}
        title="Request a cashier's check"
      >
        <div className="margin--top--s">
          <CheckWithdrawalForm
            account={account}
            address={address}
            closeDialog={closeDialog}
          />
        </div>
      </Dialog>
    </>
  );
};
CheckWithdrawal.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  address: PropTypes.object.isRequired,
};

const ServicesCard = ({ account, address, refreshStopPayments }) => {
  const { stoppayment_info = "" } = useContext(InstitutionSettingsContext);
  const userFeatures = useContext(UserFeaturesContext);
  const { t } = useTranslation();
  const { l10n } = useLocalization();

  const features = Object.freeze({
    cards: userFeatures?.cards,
    bill_pay:
      userFeatures?.bill_pay &&
      Account.PRODUCT_GROUPS.Checking.includes(account.product_type),
    stop_payment:
      account.features.includes("stop_payment") && userFeatures?.stop_payment,
    check_order:
      account.features.includes("check_order") && userFeatures?.check_order,
    check_withdrawal: account.features.includes("check_withdrawal"),
    external_loan_management: account.features.includes(
      "external_loan_management"
    ),
  });

  return (
    <Feature
      features={features}
      or={[
        "cards",
        "bill_pay",
        "stop_payment",
        "check_order",
        "check_withdrawal",
        "external_loan_management",
      ]}
    >
      <SectionCard>
        <SectionCard.Title text="Account services" />
        <Feature features={features} or="cards">
          <Button as="a" kind="plain" label={t("Manage cards")} href="/cards" />
        </Feature>
        <Feature features={features} or="bill_pay">
          <div className="margin--top--xs" />
          <BillPaySso />
        </Feature>
        <Feature features={features} or="stop_payment">
          <div className="margin--top--xs">
            <StopCheckPayment
              account={account}
              refreshStopPayments={refreshStopPayments}
              stopPaymentInfo={stoppayment_info}
            />
          </div>
        </Feature>
        <Feature features={features} or="check_order">
          <div className="margin--top--xs">
            <Button
              as="a"
              target="_blank"
              kind="plain"
              label={l10n.getString("order-checks-link")}
              href={`/v1/signed_urls/check_order/urls/${account.id}`}
            />
          </div>
        </Feature>
        <Feature features={features} or="check_withdrawal">
          <div className="margin--top--xs">
            <CheckWithdrawal account={account} address={address} />
          </div>
        </Feature>
        <Feature features={features} or="external_loan_management">
          <div className="margin--top--xs">
            <Button
              as="a"
              target="_blank"
              kind="plain"
              label="Manage my mortgage"
              href={`/v1/signed_urls/external_loan_management/urls/${account.id}`}
            />
          </div>
        </Feature>
      </SectionCard>
    </Feature>
  );
};
ServicesCard.propTypes = {
  account: PropTypes.instanceOf(Account).isRequired,
  address: PropTypes.object.isRequired,
  refreshStopPayments: PropTypes.func.isRequired,
};

export default ServicesCard;
