var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useState } from "react";
import PropTypes from "prop-types";
import { useLocalization } from "@fluent/react";
import Address from "byzantine/src/Address";
import WireRecipient from "byzantine/src/WireRecipient";
import { AccountNumberTextInput, Button, ContextForm, Dialog, RoutingNumberTextInput, TextInput, useFormData, validateRoutingNumber, } from "cerulean";
import utils from "../../../../utils";
import BankingUSAddressField from "../../../address/BankingUSAddressField";
var validateAddress = function (formData) {
    var addressFieldErrors = utils.validateAddress(formData);
    if (Object.values(addressFieldErrors).some(function (v) { return !!v; })) {
        return addressFieldErrors;
    }
    return "";
};
var EditRecipientDialog = function (_a) {
    var _b, _c, _d, _e, _f;
    var closeDialog = _a.closeDialog, isOpen = _a.isOpen, updateWireRecipient = _a.updateWireRecipient, recipient = _a.recipient;
    var l10n = useLocalization().l10n;
    var initialFormData = {
        name: recipient.name,
        street_address: (_b = recipient.address) === null || _b === void 0 ? void 0 : _b.street_address,
        street_address_2: (_c = recipient.address) === null || _c === void 0 ? void 0 : _c.street_address_2,
        city: (_d = recipient.address) === null || _d === void 0 ? void 0 : _d.city,
        region_code: (_e = recipient.address) === null || _e === void 0 ? void 0 : _e.region_code,
        postal_code: (_f = recipient.address) === null || _f === void 0 ? void 0 : _f.postal_code,
        country_code: Address.COUNTRIES.US,
        routing_number: recipient.routing_number,
        account_number: recipient.account_number,
    };
    var _g = useFormData(initialFormData), formData = _g.formData, onChange = _g.onChange, setFormData = _g.setFormData;
    var _h = __read(useState({}), 2), addressErrors = _h[0], setAddressErrors = _h[1];
    var onCloseDialog = function () {
        setFormData(initialFormData);
        setAddressErrors({});
        closeDialog();
    };
    var onSubmit = function () {
        var errors = validateAddress(formData);
        if (errors) {
            setAddressErrors(errors);
            return;
        }
        var updatedRecipient = new WireRecipient(__assign(__assign({}, recipient), { name: formData.name, address: new Address({
                street_address: formData.street_address,
                street_address_2: formData.street_address_2,
                city: formData.city,
                region_code: formData.region_code,
                postal_code: formData.postal_code,
                country_code: Address.COUNTRIES.US,
            }), routing_number: formData.routing_number, account_number: formData.account_number }));
        updateWireRecipient(updatedRecipient);
    };
    return (_jsx(Dialog, { isOpen: isOpen, onUserDismiss: onCloseDialog, title: "Edit details", children: _jsxs(ContextForm, { data: formData, onChange: onChange, children: [_jsx(ContextForm.Field, { required: true, style: { marginTop: "12px" }, children: _jsx(TextInput, { field: "name", label: l10n.getString("label-name", null, "Name"), maxLength: 35 }) }), _jsx(BankingUSAddressField, { data: formData || {}, errors: addressErrors, onUpdate: onChange, showTitle: false }), _jsx("div", { className: "margin--bottom--l", children: _jsx(ContextForm.Field, { required: true, children: _jsx(AccountNumberTextInput, { field: "account_number", label: l10n.getString("label-account", null, "Account number") }) }) }), _jsx(ContextForm.Field, { required: true, validate: validateRoutingNumber, children: _jsx(RoutingNumberTextInput, { field: "routing_number", label: l10n.getString("label-routing", null, "Routing number") }) }), _jsxs("div", { style: {
                        marginTop: "40px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "16px",
                    }, children: [_jsx(Button, { onClick: onCloseDialog, kind: "negative", type: "button", label: "Cancel" }), _jsx(ContextForm.Action, { onSubmit: onSubmit, dangerouslyDisableShowLoading: true, children: _jsx(Button, { label: "Save changes" }) })] })] }) }));
};
EditRecipientDialog.propTypes = {
    isOpen: PropTypes.bool,
    closeDialog: PropTypes.func,
    recipients: PropTypes.arrayOf(PropTypes.instanceOf(WireRecipient)),
    updateTransferFormWithRecipient: PropTypes.func,
    setRecipients: PropTypes.func,
};
export default EditRecipientDialog;
