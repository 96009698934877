import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Drawer } from "cerulean";
import CardImage from "../CardImage";
import LockCard from "./LockCard";
import ReplaceCard from "./ReplaceCard";
import styles from "./CardManagerDrawer.module.scss";
var CardManagerDrawer = function (_a) {
    var isOpen = _a.isOpen, card = _a.card, onUserDismiss = _a.onUserDismiss, updateCardStateById = _a.updateCardStateById;
    var isLocked = (card === null || card === void 0 ? void 0 : card.state) === "locked";
    if (!card) {
        return null;
    }
    return (_jsx(Drawer, { depth: "470px", isOpen: isOpen, onUserDismiss: onUserDismiss, showControls: false, children: _jsxs("div", { className: styles.cardManagerDrawerContainer, children: [_jsx(CardImage, { name: card.name, textColor: card.text_color, size: "large", isLocked: isLocked, image: card.image, lastFourDigits: card.last_four_digits, network: card.network }), _jsx(LockCard, { state: card.state, isLocked: isLocked, cardId: card.id, updateCardStateById: updateCardStateById }), _jsx(ReplaceCard, { cardId: card.id })] }) }));
};
export default CardManagerDrawer;
