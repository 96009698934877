var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RadioButtons, ProgressButtons } from "cerulean";
import { useLocalization } from "@fluent/react";
import style from "./ReplaceReasonPage.module.scss";
import HeaderWithSteps from "../../../../HeaderWithSteps";
var ReplaceReasonPage = function (_a) {
    var totalSteps = _a.totalSteps, reason = _a.reason, setReason = _a.setReason, onSubmit = _a.onSubmit;
    var _b = __read(useState(""), 2), error = _b[0], setError = _b[1];
    var navigate = useNavigate();
    var onChangeReason = function (event) {
        setError("");
        setReason(event.target.value);
    };
    var l10n = useLocalization().l10n;
    var options = useMemo(function () {
        var e_1, _a;
        var reasons = [
            "lost",
            "stolen",
            "damaged",
            "never-received",
        ];
        var reasonOptions = {};
        try {
            // eslint-disable-next-line no-restricted-syntax
            for (var reasons_1 = __values(reasons), reasons_1_1 = reasons_1.next(); !reasons_1_1.done; reasons_1_1 = reasons_1.next()) {
                var reasonValue = reasons_1_1.value;
                var reasonLabel = l10n.getString("replace-card-reason-".concat(reasonValue));
                reasonOptions[reasonLabel] = {
                    value: reasonValue,
                    details: l10n.getString("replace-card-reason-".concat(reasonValue, "-description")),
                };
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (reasons_1_1 && !reasons_1_1.done && (_a = reasons_1.return)) _a.call(reasons_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return reasonOptions;
    }, [l10n]);
    return (_jsxs(_Fragment, { children: [_jsx(HeaderWithSteps, { headerText: l10n.getString("screen-title-replace-card"), subheaderText: l10n.getString("replace-card-description"), step: 1, totalSteps: totalSteps }), _jsx("div", { className: style.radioButtons, children: _jsx(RadioButtons, { kind: "card", field: "replaceReason", onChange: onChangeReason, reason: reason, error: error, options: options }) }), _jsx("div", { className: style.progressButtonsContainer, children: _jsx(ProgressButtons, { backLabel: l10n.getString("button-back"), nextLabel: l10n.getString("button-continue"), onBack: function () {
                        navigate("/settings/cards");
                    }, onNext: function () {
                        if (!reason) {
                            setError(l10n.getString("replace-card-error-select-reason"));
                            return;
                        }
                        onSubmit();
                    } }) })] }));
};
export default ReplaceReasonPage;
